.comp-dashboard {
  padding: 1rem; }
  .comp-dashboard .dashboard-grid {
    position: relative; }
  .comp-dashboard .dashboard-item {
    display: block;
    position: absolute;
    min-width: 10em;
    margin: 5px;
    z-index: 1; }
    .comp-dashboard .dashboard-item .dashboard-item-content {
      position: relative;
      width: 100%;
      height: 100%; }
  .comp-dashboard .dashboard-item.muuri-item-dragging {
    z-index: 3; }
  .comp-dashboard .dashboard-item.muuri-item-releasing {
    z-index: 2; }
  .comp-dashboard .dashboard-item.muuri-item-hidden {
    z-index: 0; }
