@import "../../styles/size-mixins.scss";
.view-partner-comp {
  display: flex;
  flex-direction: column;
  align-items: center;

  .content-container {
    margin: 1rem 1rem 0;
    max-width: 50em;
    width: 100%;

    @include for-size-down(390px) {
      padding-left: 0;
      padding-right: 0;
      border-radius: 0;

      .name-container {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .name-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-bottom: 1rem;

      .title {
        margin: 0;
      }

      .edit-button {
        i {
          margin-right: 0.2rem;
        }
      }
    }

    .list {
      .list-item {
        display: flex;

        @include for-size-down(390px) {
          flex-direction: column;
        }

        .item-label {
          min-width: 7em;
          font-weight: bold;
        }

        .item-value {
          overflow-wrap: break-word;
          word-break: break-all;
        }
      }
    }

    form {
      .button-container {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;
      }
    }
  }
}
