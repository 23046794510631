.scanner-overlay .scanner-container {
  width: 100%;
  height: calc(100% - 4em); }
  .scanner-overlay .scanner-container canvas {
    position: absolute;
    z-index: 4; }

.scanner-overlay .barcode-display {
  width: 100%;
  height: 4em;
  background-color: black;
  z-index: 2; }
  .scanner-overlay .barcode-display span {
    color: white;
    font-size: 2em; }

.scanner-overlay .error-message {
  position: absolute;
  text-align: center;
  color: white;
  font-size: 2em;
  transform: translateY(-50%); }
