/*Screen Sizes*/
/*Colors*/
.comp-container.comp-check {
  width: 100%;
  min-height: calc(100vh - 2.8em);
  position: relative;
  padding: 1rem 1rem; }
  .comp-container.comp-check .content-container {
    min-height: 20em; }
  .comp-container.comp-check .complete-button {
    margin-left: 1rem; }
  .comp-container.comp-check .scan-button {
    position: absolute;
    bottom: 2.5rem;
    right: 2.5rem;
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    font-size: 1.5em;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1); }
    @media (max-width: 599px) {
      .comp-container.comp-check .scan-button {
        bottom: 1.5rem;
        right: 1.5rem;
        width: 2.4em;
        height: 2.4em;
        font-size: 1.4em; } }
  .comp-container.comp-check .scan-button:hover {
    box-shadow: 0 3px 4px rgba(10, 10, 10, 0.1), 0 0 0 2px rgba(10, 10, 10, 0.1); }
  .comp-container.comp-check .item-list {
    width: calc(100% - 2em);
    margin-top: 2rem;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    position: relative; }
    .comp-container.comp-check .item-list .list-item {
      width: 100%;
      margin-bottom: 1rem;
      position: relative; }
      .comp-container.comp-check .item-list .list-item .delete {
        display: block;
        position: absolute;
        right: 1rem;
        top: 1rem; }
