.lend-history-comp {
  .lend-history-list {
    margin: 0 2rem 1rem;
  }

  .info-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0 1rem;
    font-size: 1.1em;
    padding: 0.1rem 3rem;
    .returned {
      margin-left: 1rem;
    }
  }
}
