.comp-lend {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .input-container {
      width: 80%;
      max-width: 600px;
      min-width: 500px;
      padding: 0 1rem;
      .input-group-top {
        display: flex;

        .is-expanded {
          margin-left: 0.5rem;
          flex-grow: 1;
        }
        .input-lend-fileno {
          min-width: 90px;
        }
        .input-lend-limit {
          min-width: 90px;
        }
      }
      .lend-dropdown {
        background-color: white;
      }




    }

    .list-container {
      margin-top: 1rem;
      width: 70%;
    }
  }

}
