/*Screen Sizes*/
/*Colors*/
.comp-browse {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /*.search-container {
    .control {
      margin-right: 0.5rem;
      .button {
        width: 2.4em;
        height: calc(100% - 0.6rem);
        position: absolute;
        right: 0.4rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }*/ }
  .comp-browse .filter-container {
    margin: 0.5rem 1rem 0rem; }
  .comp-browse .item-list {
    width: calc(100% - 2em);
    margin-top: 1rem;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    position: relative; }
    .comp-browse .item-list .list-item {
      width: 100%;
      user-select: none;
      cursor: pointer;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      position: relative; }
      @media (min-width: 600px) {
        .comp-browse .item-list .list-item {
          padding: 1rem; } }
