@import "../../../styles/variables.scss";
.item-details-copy {
  cursor: pointer;
  &:hover {
    background-color: #fafafa !important;
  }
  .copy-header-container {
    i {
      margin-right: 0.5rem;
    }
    font-size: 1.1em;
    padding: 0.2rem 1rem;
    border-bottom: 1px solid $blue-grey-300;

    .copy-title-container {
      display: flex;
      justify-content: space-between;
    }
  }

  .other-barcode-container {

  }
}
