.comp-dashboard .dashboard-item {
  margin-top: 1.5rem; }
  .comp-dashboard .dashboard-item a {
    color: black;
    border-bottom: 1px dashed black; }
  .comp-dashboard .dashboard-item.is-chart {
    width: calc(100vw - 3rem);
    height: calc(200px + 5rem); }
    .comp-dashboard .dashboard-item.is-chart .item-content {
      height: 100%; }
  .comp-dashboard .dashboard-item .item-header {
    color: white;
    padding: 0.2rem 1rem;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0.5rem;
    transform: translateY(-50%) !important;
    border-radius: 100000px;
    cursor: grab;
    outline: none;
    border: none; }
    .comp-dashboard .dashboard-item .item-header:focus {
      cursor: grabbing; }
