.comp-item-details .back-button-bar {
  background-color: white; }
  .comp-item-details .back-button-bar .back-button {
    height: 3em;
    padding: 0.5rem;
    line-height: 3em;
    cursor: pointer; }

.comp-item-details .container {
  padding: 1rem 0; }
  .comp-item-details .container .list {
    width: 100%; }
    .comp-item-details .container .list .title-list-item {
      justify-content: space-between;
      align-items: center; }
      .comp-item-details .container .list .title-list-item span {
        display: block;
        margin: 0; }
      .comp-item-details .container .list .title-list-item .title {
        width: 100%;
        text-align: center; }
      .comp-item-details .container .list .title-list-item .subtitle {
        min-width: 3em;
        text-align: center; }
    .comp-item-details .container .list .copies-list-item .list .list-item {
      cursor: pointer;
      justify-content: space-between; }
      .comp-item-details .container .list .copies-list-item .list .list-item i {
        cursor: pointer;
        padding: 0.5rem;
        height: 1.6em; }
  .comp-item-details .container .columns {
    width: 100%; }
    .comp-item-details .container .columns .column {
      padding: 1rem; }
