@import "../../../styles/variables.scss";
.item-copy-details {
  .header-container {
    i {
      margin-right: 0.5rem;
    }
    font-size: 1.1em;
    padding: 0.2rem 1rem;
    border-bottom: 1px solid $blue-grey-300;
    display: flex;
    justify-content: space-between;



    .back-container {
      cursor: pointer;
    }
  }

  .info-container {
    .info-item {
      margin-bottom: 0.3rem;
    }
  }
}
