.comp-add-item .form-container {
  width: 80%;
  max-width: 50rem;
  padding-bottom: 1rem; }
  .comp-add-item .form-container .copy-list .copy-item {
    position: relative; }
    .comp-add-item .form-container .copy-list .copy-item .delete {
      position: absolute;
      top: 0.5rem;
      right: 1rem; }
    .comp-add-item .form-container .copy-list .copy-item .btn-duplicate-copy {
      position: absolute;
      top: 0.5rem;
      right: 3rem; }
