@mixin for-size-up($size) {
  @if $size == tablet-portrait {
    @media (min-width: 600px) { @content; }
  } @else if $size == tablet-landscape {
    @media (min-width: 900px) { @content; }
  } @else if $size == desktop {
    @media (min-width: 1200px) { @content; }
  } @else if $size == big-desktop {
    @media (min-width: 1800px) { @content; }
  } @else {
    @media (min-width: $size) { @content; }
  }
}

@mixin for-size-down($size) {
  @if $size == tablet-portrait {
    @media (max-width: 600px) { @content; }
  } @else if $size == tablet-landscape {
    @media (max-width: 900px) { @content; }
  } @else if $size == desktop {
    @media (max-width: 1200px) { @content; }
  } @else if $size == big-desktop {
    @media (max-width: 1800px) { @content; }
  } @else {
    @media (max-width: $size) { @content;}
  }
}
