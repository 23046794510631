@charset "UTF-8";
/*Screen Sizes*/
/*Colors*/
.ld.reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse; }

.ld.xhalf {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s; }

.ld.x1 {
  -webkit-animation-duration: 1s;
  animation-duration: 1s; }

.ld.x2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s; }

.ld.x4 {
  -webkit-animation-duration: 4s;
  animation-duration: 4s; }

.ld.running {
  -webkit-animation-play-state: running;
  animation-play-state: running; }

.ld.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused; }

.ld.f00 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s; }

.ld.f01 {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s; }

.ld.f02 {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s; }

.ld.f03 {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s; }

.ld.f04 {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s; }

.ld.f05 {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s; }

.ld.f06 {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s; }

.ld.f07 {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s; }

.ld.f08 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s; }

.ld.f09 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s; }

.ld.f10 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s; }

.ld-ball, .ld-ring, .ld-hourglass, .ld-loader, .ld-cross, .ld-square, .ld-pie, .ld-spinner {
  width: 1em;
  height: 1em;
  position: relative;
  color: inherit;
  display: inline-block;
  /*
    &:before
      content: "◎"
      display: block
      visibility: hidden
    */ }

.ld-ball:after, .ld-ring:after, .ld-hourglass:after, .ld-loader:after, .ld-cross:after, .ld-square:after, .ld-pie:after, .ld-spinner:after {
  position: absolute;
  margin: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: " ";
  display: inline-block;
  background: center center no-repeat;
  background-size: cover; }

.ld-ball:after {
  border-radius: 50%;
  background: currentColor; }

.ld-pie:after {
  width: 0;
  height: 0;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent; }

.ld-ring:after {
  border-radius: 50%;
  border-style: solid;
  border-width: 0.15em;
  -webkit-background-clip: padding-box;
  border-color: currentColor currentColor currentColor transparent;
  box-sizing: border-box; }

.ld-hourglass:after {
  width: 0;
  height: 0;
  background: none;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5em;
  border-color: currentColor transparent currentColor transparent; }

.ld-cross:after {
  width: 18%;
  height: 18%;
  background: currentColor;
  box-shadow: 0 0.18em 0 1px currentColor, 0 -0.18em 0 1px currentColor, 0.18em 0 0 1px currentColor, -0.18em 0 0 1px currentColor, 0 0.36em 0 1px currentColor, 0 -0.36em 0 1px currentColor, 0.36em 0 0 1px currentColor, -0.36em 0 0 1px currentColor; }

.ld-square:after {
  width: 90%;
  height: 90%;
  background: currentColor; }

.ld-spinner:after {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background: none;
  box-shadow: 0 0.5em 0 0 currentColor,0 -.5em 0 0 currentColor,.5em 0 0 0 currentColor,-.5em 0 0 0 currentColor,.35355339059327373em .35355339059327373em 0 0 currentColor,-.35355339059327373em .35355339059327373em 0 0 currentColor,.35355339059327373em -.35355339059327373em 0 0 currentColor,-.35355339059327373em -.35355339059327373em 0 0 currentColor; }

.ld-loader {
  background-size: cover; }

@keyframes ld-blink {
  0% {
    opacity: 1; }
  49% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-webkit-keyframes ld-blink {
  0% {
    opacity: 1; }
  49% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.ld.ld-blink {
  -webkit-animation: ld-blink 1s infinite linear;
  animation: ld-blink 1s infinite linear; }

.ld.ld-blur {
  -webkit-animation: ld-blur 1s infinite;
  animation: ld-blur 1s infinite; }

.ld.ld-breath {
  -webkit-animation: ld-breath 1s infinite;
  animation: ld-breath 1s infinite; }

.ld.ld-broadcast {
  -webkit-animation: ld-broadcast 1s infinite ease-out;
  animation: ld-broadcast 1s infinite ease-out;
  border-radius: 50%; }

.ld.ld-clock {
  -webkit-animation: ld-clock 9s infinite cubic-bezier(0, 0.7, 0.3, 1);
  animation: ld-clock 9s infinite cubic-bezier(0, 0.7, 0.3, 1); }

.ld.ld-cycle {
  -webkit-animation: ld-cycle 1s infinite linear;
  animation: ld-cycle 1s infinite linear; }

.ld.ld-fade {
  -webkit-animation: ld-fade 1s infinite linear;
  animation: ld-fade 1s infinite linear; }

.ld.ld-flip {
  -webkit-animation: ld-flip 2s infinite;
  animation: ld-flip 2s infinite; }

.ld.ld-flip-v {
  -webkit-animation: ld-flip-v 1s infinite;
  animation: ld-flip-v 1s infinite; }

.ld.ld-flip-h {
  -webkit-animation: ld-flip-h 1s infinite;
  animation: ld-flip-h 1s infinite; }

.ld.ld-coin-v {
  -webkit-animation: ld-coin-v 2s infinite;
  animation: ld-coin-v 2s infinite; }

.ld.ld-coin-h {
  -webkit-animation: ld-coin-h 2s infinite;
  animation: ld-coin-h 2s infinite; }

.ld.ld-heartbeat {
  -webkit-animation: ld-heartbeat 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ld-heartbeat 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1); }

.ld.ld-radio {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0; }
  .ld.ld-radio:after {
    -webkit-animation: ld-radio 1s infinite;
    animation: ld-radio 1s infinite;
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 6px solid #000;
    border-radius: 50%;
    background: none; }
  .ld.ld-radio.square:after {
    border-radius: 0; }

.ld.ld-rotate {
  -webkit-animation: ld-rotate 1s infinite;
  animation: ld-rotate 1s infinite; }

.ld.ld-rubber {
  -webkit-animation: ld-rubber 1s ease-out infinite;
  animation: ld-rubber 1s ease-out infinite; }

.ld.ld-shadow {
  -webkit-animation: ld-shadow 1s infinite;
  animation: ld-shadow 1s infinite;
  border-radius: 50%; }

.ld.ld-shadow-a {
  -webkit-animation: ld-shadow-a 0.5s infinite;
  animation: ld-shadow-a 0.5s infinite;
  box-shadow: 3px 0px 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 50%; }

.ld.ld-skew {
  -webkit-animation: ld-skew 1s infinite;
  animation: ld-skew 1s infinite; }

.ld.ld-spin {
  -webkit-animation: ld-spin 1s infinite;
  animation: ld-spin 1s infinite; }

.ld.ld-spin-fast {
  -webkit-animation: ld-spin-fast 1s infinite;
  animation: ld-spin-fast 1s infinite; }

.ld.ld-squeeze {
  -webkit-animation: ld-squeeze 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ld-squeeze 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1); }

.ld.ld-surprise {
  -webkit-animation: ld-surprise 1s infinite linear;
  animation: ld-surprise 1s infinite linear; }

.ld.ld-tick {
  -webkit-animation: ld-tick 1s ease-out infinite;
  animation: ld-tick 1s ease-out infinite; }

.ld.ld-vortex-in {
  -webkit-animation: ld-vortex-in 2s infinite;
  animation: ld-vortex-in 2s infinite;
  animation-timing-function: cubic-bezier(0.3, 0, 1, 0.7); }

.ld.ld-vortex-out {
  -webkit-animation: ld-vortex-out 2s infinite;
  animation: ld-vortex-out 2s infinite;
  animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }

.ld.ld-wrench {
  -webkit-animation: ld-wrench 1s infinite;
  animation: ld-wrench 1s infinite; }

.ld.ld-pulse {
  -webkit-animation: ld-pulse 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: ld-pulse 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1); }

.ld.ld-bounce {
  -webkit-animation: ld-bounce 1s infinite;
  animation: ld-bounce 1s infinite; }

.ld.ld-bounce-rtl {
  -webkit-animation: ld-bounce-rtl 1s infinite linear;
  animation: ld-bounce-rtl 1s infinite linear; }

.ld.ld-bounce-ltr {
  -webkit-animation: ld-bounce-ltr 1s infinite linear;
  animation: ld-bounce-ltr 1s infinite linear; }

.ld.ld-bounce-a-px {
  -webkit-animation: ld-bounce-a-px 2s infinite;
  animation: ld-bounce-a-px 2s infinite; }

.ld.ld-float {
  -webkit-animation: ld-float 1s infinite;
  animation: ld-float 1s infinite; }

.ld.ld-hit {
  -webkit-animation: ld-hit 2s infinite;
  animation: ld-hit 2s infinite; }

.ld.ld-jelly {
  -webkit-animation: ld-jelly 1s infinite linear;
  animation: ld-jelly 1s infinite linear; }

.ld.ld-jump {
  -webkit-animation: ld-jump 1.5s ease-in infinite;
  animation: ld-jump 1.5s ease-in infinite; }

.ld.ld-orbit {
  -webkit-animation: ld-orbit 1s infinite linear;
  animation: ld-orbit 1s infinite linear; }

.ld.ld-rush-rtl {
  -webkit-animation: ld-rush-rtl 1.5s infinite linear;
  animation: ld-rush-rtl 1.5s infinite linear; }

.ld.ld-rush-ltr {
  -webkit-animation: ld-rush-ltr 1.5s infinite linear;
  animation: ld-rush-ltr 1.5s infinite linear; }

.ld.ld-shake {
  -webkit-animation: ld-shake 1s infinite linear;
  animation: ld-shake 1s infinite linear; }

.ld.ld-slide-ltr {
  -webkit-animation: ld-slide-ltr 1s infinite;
  animation: ld-slide-ltr 1s infinite; }

.ld.ld-slide-rtl {
  -webkit-animation: ld-slide-rtl 1s infinite;
  animation: ld-slide-rtl 1s infinite; }

.ld.ld-slide-btt {
  -webkit-animation: ld-slide-btt 1s infinite;
  animation: ld-slide-btt 1s infinite; }

.ld.ld-slide-ttb {
  -webkit-animation: ld-slide-ttb 1s infinite;
  animation: ld-slide-ttb 1s infinite; }

.ld.ld-tremble {
  -webkit-animation: ld-tremble 1s infinite;
  animation: ld-tremble 1s infinite; }

.ld.ld-wander-h {
  -webkit-animation: ld-wander-h 1s infinite ease-out;
  animation: ld-wander-h 1s infinite ease-out; }

.ld.ld-wander-v {
  -webkit-animation: ld-wander-v 1s infinite ease-out;
  animation: ld-wander-v 1s infinite ease-out; }

.ld.ld-jingle {
  -webkit-animation: ld-jingle 1s infinite;
  animation: ld-jingle 1s infinite; }

.ld.ld-swim {
  -webkit-animation: ld-swim 3s infinite linear;
  animation: ld-swim 3s infinite linear; }

.ld.ld-leaf {
  -webkit-animation: ld-leaf 4s infinite cubic-bezier(0.1, 0.5, 0.1, 0.5);
  animation: ld-leaf 4s infinite cubic-bezier(0.1, 0.5, 0.1, 0.5); }

.ld.ld-slot {
  -webkit-animation: ld-slot 6s infinite linear;
  animation: ld-slot 6s infinite linear; }

.ld.ld-bounce-px {
  -webkit-animation: ld-bounce-px 1s infinite;
  animation: ld-bounce-px 1s infinite; }

.ld.ld-bounce-px-rtl {
  -webkit-animation: ld-bounce-px-rtl 1s infinite linear;
  animation: ld-bounce-px-rtl 1s infinite linear; }

.ld.ld-bounce-px-ltr {
  -webkit-animation: ld-bounce-px-ltr 1s infinite linear;
  animation: ld-bounce-px-ltr 1s infinite linear; }

.ld.ld-bounce-a-px {
  -webkit-animation: ld-bounce-a-px 2s infinite;
  animation: ld-bounce-a-px 2s infinite; }

.ld.ld-float-px {
  -webkit-animation: ld-float-px 1s infinite;
  animation: ld-float-px 1s infinite; }

.ld.ld-hit-px {
  -webkit-animation: ld-hit-px 2s infinite;
  animation: ld-hit-px 2s infinite; }

.ld.ld-jelly-px {
  -webkit-animation: ld-jelly-px 1s infinite linear;
  animation: ld-jelly-px 1s infinite linear; }

.ld.ld-jump-px {
  -webkit-animation: ld-jump-px 1.5s ease-in infinite;
  animation: ld-jump-px 1.5s ease-in infinite; }

.ld.ld-orbit-px {
  -webkit-animation: ld-orbit-px 1s infinite linear;
  animation: ld-orbit-px 1s infinite linear; }

.ld.ld-rush-px-rtl {
  -webkit-animation: ld-rush-px-rtl 1.5s infinite linear;
  animation: ld-rush-px-rtl 1.5s infinite linear; }

.ld.ld-rush-px-ltr {
  -webkit-animation: ld-rush-px-ltr 1.5s infinite linear;
  animation: ld-rush-px-ltr 1.5s infinite linear; }

.ld.ld-shake-px {
  -webkit-animation: ld-shake-px 1s infinite linear;
  animation: ld-shake-px 1s infinite linear; }

.ld.ld-slide-px-ltr {
  -webkit-animation: ld-slide-px-ltr 1s infinite;
  animation: ld-slide-px-ltr 1s infinite; }

.ld.ld-slide-px-rtl {
  -webkit-animation: ld-slide-px-rtl 1s infinite;
  animation: ld-slide-px-rtl 1s infinite; }

.ld.ld-slide-px-btt {
  -webkit-animation: ld-slide-px-btt 1s infinite;
  animation: ld-slide-px-btt 1s infinite; }

.ld.ld-slide-px-ttb {
  -webkit-animation: ld-slide-px-ttb 1s infinite;
  animation: ld-slide-px-ttb 1s infinite; }

.ld.ld-tremble-px {
  -webkit-animation: ld-tremble-px 1s infinite;
  animation: ld-tremble-px 1s infinite; }

.ld.ld-wander-px-h {
  -webkit-animation: ld-wander-px-h 1s infinite ease-out;
  animation: ld-wander-px-h 1s infinite ease-out; }

.ld.ld-wander-px-v {
  -webkit-animation: ld-wander-px-v 1s infinite ease-out;
  animation: ld-wander-px-v 1s infinite ease-out; }

.ld.ld-jingle-px {
  -webkit-animation: ld-jingle-px 1s infinite;
  animation: ld-jingle-px 1s infinite; }

.ld.ld-swim-px {
  -webkit-animation: ld-swim-px 3s infinite linear;
  animation: ld-swim-px 3s infinite linear; }

.ld.ld-leaf-px {
  -webkit-animation: ld-leaf-px 4s infinite cubic-bezier(0.1, 0.5, 0.1, 0.5);
  animation: ld-leaf-px 4s infinite cubic-bezier(0.1, 0.5, 0.1, 0.5); }

.ld.ld-slot-px {
  -webkit-animation: ld-slot-px 6s infinite linear;
  animation: ld-slot-px 6s infinite linear; }

@keyframes ld-blur {
  0% {
    filter: blur(0); }
  50% {
    filter: blur(5px); }
  100% {
    filter: blur(0); } }

@-webkit-keyframes ld-blur {
  0% {
    filter: blur(0); }
  50% {
    filter: blur(5px); }
  100% {
    filter: blur(0); } }

@keyframes ld-breath {
  0% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86); }
  50% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06); }
  100% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86); } }

@-webkit-keyframes ld-breath {
  0% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86); }
  50% {
    -webkit-transform: scale(1.06);
    transform: scale(1.06); }
  100% {
    -webkit-transform: scale(0.86);
    transform: scale(0.86); } }

@keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9); }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7); }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8); }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6); }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7); }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  100% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); } }

@-webkit-keyframes ld-broadcast {
  0% {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.9); }
  19% {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.7); }
  20% {
    box-shadow: 0 0 0 6px rgba(0, 0, 0, 0.8); }
  39% {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.6); }
  40% {
    box-shadow: 0 0 0 9px rgba(0, 0, 0, 0.7); }
  60% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.6);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  100% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2); } }

@keyframes ld-clock {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg); }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg); }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg); }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg); }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg); }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes ld-clock {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  8.333% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); }
  16.667% {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg); }
  25% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  33.333% {
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg); }
  41.667% {
    -webkit-transform: rotate(150deg);
    transform: rotate(150deg); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  58.333% {
    -webkit-transform: rotate(210deg);
    transform: rotate(210deg); }
  66.667% {
    -webkit-transform: rotate(240deg);
    transform: rotate(240deg); }
  75% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  83.333% {
    -webkit-transform: rotate(300deg);
    transform: rotate(300deg); }
  91.667% {
    -webkit-transform: rotate(330deg);
    transform: rotate(330deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes ld-cycle {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0.5, 0.5, 0.5); }
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes ld-fade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes ld-fade {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0deg);
    transform: rotateY(180deg) rotateX(0deg); }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg); }
  75% {
    -webkit-transform: rotateY(0deg) rotateX(180deg);
    transform: rotateY(0deg) rotateX(180deg); }
  100% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); } }

@-webkit-keyframes ld-flip {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); }
  25% {
    -webkit-transform: rotateY(180deg) rotateX(0deg);
    transform: rotateY(180deg) rotateX(0deg); }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(180deg);
    transform: rotateY(180deg) rotateX(180deg); }
  75% {
    -webkit-transform: rotateY(0deg) rotateX(180deg);
    transform: rotateY(0deg) rotateX(180deg); }
  100% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); } }

@keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  100% {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg); } }

@-webkit-keyframes ld-flip-v {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg); }
  50% {
    -webkit-transform: rotateX(180deg) rotateY(0deg);
    transform: rotateX(180deg) rotateY(0deg); }
  100% {
    -webkit-transform: rotateX(0deg) rotateY(0deg);
    transform: rotateX(0deg) rotateY(0deg); } }

@keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0deg);
    transform: rotateY(180deg) rotateX(0deg); }
  100% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); } }

@-webkit-keyframes ld-flip-h {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); }
  50% {
    -webkit-transform: rotateY(180deg) rotateX(0deg);
    transform: rotateY(180deg) rotateX(0deg); }
  100% {
    -webkit-transform: rotateY(0deg) rotateX(0deg);
    transform: rotateY(0deg) rotateX(0deg); } }

@keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg); }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg); } }

@-webkit-keyframes ld-coin-v {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  50% {
    -webkit-transform: rotateX(1800deg);
    transform: rotateX(1800deg); }
  100% {
    -webkit-transform: rotateX(3600deg);
    transform: rotateX(3600deg); } }

@keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg); }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg); } }

@-webkit-keyframes ld-coin-h {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  50% {
    -webkit-transform: rotateY(1800deg);
    transform: rotateY(1800deg); }
  100% {
    -webkit-transform: rotateY(3600deg);
    transform: rotateY(3600deg); } }

@keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15); }
  60% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes ld-heartbeat {
  0% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  5% {
    -webkit-transform: scale(1.25);
    transform: scale(1.25); }
  39% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  45% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15); }
  60% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1); }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@-webkit-keyframes ld-radio {
  0% {
    animation-timing-function: cubic-bezier(0.3, 0.27, 0.13, 1); }
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0; }
  5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0; } }

@keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5);
    transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5); }
  16% {
    -webkit-transform: skewX(45deg) skewY(0deg) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0deg) scaleX(1) scaleY(1); }
  33% {
    -webkit-transform: skewX(0deg) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0deg) skewY(45deg) scaleX(1) scaleY(1); }
  50% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(0.5) scaleY(2);
    transform: skewX(0deg) skewY(0deg) scaleX(0.5) scaleY(2); }
  66% {
    -webkit-transform: skewX(0deg) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0deg) skewY(-45deg) scaleX(1) scaleY(1); }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0deg) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0deg) scaleX(1) scaleY(1); }
  100% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5);
    transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5); } }

@-webkit-keyframes ld-rotate {
  0%, 33%, 66%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  16%, 50%, 83% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5);
    transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5); }
  16% {
    -webkit-transform: skewX(45deg) skewY(0deg) scaleX(1) scaleY(1);
    transform: skewX(45deg) skewY(0deg) scaleX(1) scaleY(1); }
  33% {
    -webkit-transform: skewX(0deg) skewY(45deg) scaleX(1) scaleY(1);
    transform: skewX(0deg) skewY(45deg) scaleX(1) scaleY(1); }
  50% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(0.5) scaleY(2);
    transform: skewX(0deg) skewY(0deg) scaleX(0.5) scaleY(2); }
  66% {
    -webkit-transform: skewX(0deg) skewY(-45deg) scaleX(1) scaleY(1);
    transform: skewX(0deg) skewY(-45deg) scaleX(1) scaleY(1); }
  83% {
    -webkit-transform: skewX(-45deg) skewY(0deg) scaleX(1) scaleY(1);
    transform: skewX(-45deg) skewY(0deg) scaleX(1) scaleY(1); }
  100% {
    -webkit-transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5);
    transform: skewX(0deg) skewY(0deg) scaleX(2) scaleY(0.5); } }

@keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1); }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1); }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1); }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1); }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1); }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1); }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1); }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@-webkit-keyframes ld-rubber {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); }
  20% {
    -webkit-transform: scale(1.5, 1);
    transform: scale(1.5, 1); }
  30% {
    -webkit-transform: scale(0.8, 1);
    transform: scale(0.8, 1); }
  40% {
    -webkit-transform: scale(1.3, 1);
    transform: scale(1.3, 1); }
  50% {
    -webkit-transform: scale(0.85, 1);
    transform: scale(0.85, 1); }
  60% {
    -webkit-transform: scale(1.2, 1);
    transform: scale(1.2, 1); }
  70% {
    -webkit-transform: scale(0.9, 1);
    transform: scale(0.9, 1); }
  80% {
    -webkit-transform: scale(1.1, 1);
    transform: scale(1.1, 1); }
  90% {
    -webkit-transform: scale(0.95, 1);
    transform: scale(0.95, 1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1); } }

@keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1); }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9); }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); } }

@-webkit-keyframes ld-shadow {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1); }
  0% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); }
  50% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.9); }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); } }

@keyframes ld-shadow-a {
  0% {
    box-shadow: 3px 0px 0 6px rgba(0, 0, 0, 0.5); }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5); }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5); }
  25% {
    box-shadow: 0px 3px 0 6px rgba(0, 0, 0, 0.5); }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5); }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5); }
  50% {
    box-shadow: -3px 0px 0 6px rgba(0, 0, 0, 0.5); }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5); }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5); }
  75% {
    box-shadow: 0px -3px 0 6px rgba(0, 0, 0, 0.5); }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5); }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5); }
  100% {
    box-shadow: 3px 0px 0 6px rgba(0, 0, 0, 0.5); } }

@-webkit-keyframes ld-shadow-a {
  0% {
    box-shadow: 3px 0px 0 6px rgba(0, 0, 0, 0.5); }
  8.33% {
    box-shadow: 3px 1px 0 6px rgba(0, 0, 0, 0.5); }
  16.67% {
    box-shadow: 2px 3px 0 6px rgba(0, 0, 0, 0.5); }
  25% {
    box-shadow: 0px 3px 0 6px rgba(0, 0, 0, 0.5); }
  33.33% {
    box-shadow: -1px 3px 0 6px rgba(0, 0, 0, 0.5); }
  41.67% {
    box-shadow: -3px 1px 0 6px rgba(0, 0, 0, 0.5); }
  50% {
    box-shadow: -3px 0px 0 6px rgba(0, 0, 0, 0.5); }
  58.33% {
    box-shadow: -3px -1px 0 6px rgba(0, 0, 0, 0.5); }
  66.67% {
    box-shadow: -2px -3px 0 6px rgba(0, 0, 0, 0.5); }
  75% {
    box-shadow: 0px -3px 0 6px rgba(0, 0, 0, 0.5); }
  83.33% {
    box-shadow: 2px -3px 0 6px rgba(0, 0, 0, 0.5); }
  91.67% {
    box-shadow: 3px -2px 0 6px rgba(0, 0, 0, 0.5); }
  100% {
    box-shadow: 3px 0px 0 6px rgba(0, 0, 0, 0.5); } }

@keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1); }
  25% {
    -webkit-transform: skewX(0deg) scale(0.9);
    transform: skewX(0deg) scale(0.9); }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1); }
  75% {
    -webkit-transform: skewX(0deg) scale(0.9);
    transform: skewX(0deg) scale(0.9); }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1); } }

@-webkit-keyframes ld-skew {
  0%, 50%, 100% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  25%, 75% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  0% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1); }
  25% {
    -webkit-transform: skewX(0deg) scale(0.9);
    transform: skewX(0deg) scale(0.9); }
  50% {
    -webkit-transform: skewX(-20deg) scale(1);
    transform: skewX(-20deg) scale(1); }
  75% {
    -webkit-transform: skewX(0deg) scale(0.9);
    transform: skewX(0deg) scale(0.9); }
  100% {
    -webkit-transform: skewX(20deg) scale(1);
    transform: skewX(20deg) scale(1); } }

@keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes ld-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg); } }

@-webkit-keyframes ld-spin-fast {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  50% {
    -webkit-transform: rotate(900deg);
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  100% {
    -webkit-transform: rotate(1800deg);
    transform: rotate(1800deg); } }

@keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5); }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1); }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5); } }

@-webkit-keyframes ld-squeeze {
  0% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5); }
  50% {
    -webkit-transform: scale(0.5, 1);
    transform: scale(0.5, 1); }
  100% {
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5); } }

@keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0deg) scale(1);
    transform: skewX(0deg) scale(1); }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8); }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8); }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); } }

@-webkit-keyframes ld-surprise {
  0% {
    -webkit-transform: skewX(0deg) scale(1);
    transform: skewX(0deg) scale(1); }
  10% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8); }
  20% {
    -webkit-transform: skewX(-15deg) scale(0.8);
    transform: skewX(-15deg) scale(0.8); }
  30% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  40% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  50% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  60% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  70% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  80% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); }
  90% {
    -webkit-transform: skewX(15deg) scale(1.3);
    transform: skewX(15deg) scale(1.3); }
  100% {
    -webkit-transform: skewX(-15deg) scale(1.3);
    transform: skewX(-15deg) scale(1.3); } }

@keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg); }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg); }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes ld-tick {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  20% {
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg); }
  30% {
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg); }
  40% {
    -webkit-transform: rotate(-21deg);
    transform: rotate(-21deg); }
  50% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  70% {
    -webkit-transform: rotate(6deg);
    transform: rotate(6deg); }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(1deg);
    transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0; }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes ld-vortex-in {
  0% {
    -webkit-transform: rotate(1800deg) scale(3);
    transform: rotate(1800deg) scale(3);
    opacity: 0; }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1; }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0; } }

@-webkit-keyframes ld-vortex-out {
  0% {
    -webkit-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1; }
  60% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: rotate(1800deg) scale(1);
    transform: rotate(1800deg) scale(1);
    opacity: 0; } }

@keyframes ld-wrench {
  20%, 36%, 70%, 86% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  0%, 50%, 100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); } }

@-webkit-keyframes ld-wrench {
  20%, 36%, 70%, 86% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  0%, 50%, 100% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); } }

@keyframes ld-pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  51% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@-webkit-keyframes ld-pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  51% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); } }

@keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear; }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0%, 30%) scaleY(0.5);
    transform: translate(0%, 30%) scaleY(0.5); }
  10% {
    -webkit-transform: translate(0%, 5%) scaleY(1.1);
    transform: translate(0%, 5%) scaleY(1.1); }
  50% {
    -webkit-transform: translate(0%, -37%) scaleY(1.1);
    transform: translate(0%, -37%) scaleY(1.1); }
  90% {
    -webkit-transform: translate(0%, 5%) scaleY(1.1);
    transform: translate(0%, 5%) scaleY(1.1); }
  100% {
    -webkit-transform: translate(0%, 30%) scaleY(0.5);
    transform: translate(0%, 30%) scaleY(0.5); } }

@-webkit-keyframes ld-bounce {
  0%, 90% {
    animation-timing-function: linear; }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0%, 30%) scaleY(0.5);
    transform: translate(0%, 30%) scaleY(0.5); }
  10% {
    -webkit-transform: translate(0%, 5%) scaleY(1.1);
    transform: translate(0%, 5%) scaleY(1.1); }
  50% {
    -webkit-transform: translate(0%, -37%) scaleY(1.1);
    transform: translate(0%, -37%) scaleY(1.1); }
  90% {
    -webkit-transform: translate(0%, 5%) scaleY(1.1);
    transform: translate(0%, 5%) scaleY(1.1); }
  100% {
    -webkit-transform: translate(0%, 30%) scaleY(0.5);
    transform: translate(0%, 30%) scaleY(0.5); } }

@keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%); }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%); }
  25% {
    -webkit-transform: translate(80%, 0%);
    transform: translate(80%, 0%); }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%); }
  50% {
    -webkit-transform: translate(0%, -40%);
    transform: translate(0%, -40%); }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%); }
  75% {
    -webkit-transform: translate(-80%, 0%);
    transform: translate(-80%, 0%); }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%); }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%); } }

@-webkit-keyframes ld-bounce-rtl {
  0% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%); }
  12.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%); }
  25% {
    -webkit-transform: translate(80%, 0%);
    transform: translate(80%, 0%); }
  37.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%); }
  50% {
    -webkit-transform: translate(0%, -40%);
    transform: translate(0%, -40%); }
  62.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%); }
  75% {
    -webkit-transform: translate(-80%, 0%);
    transform: translate(-80%, 0%); }
  87.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%); }
  100% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%); } }

@keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%); }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%); }
  25% {
    -webkit-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%); }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%); }
  50% {
    -webkit-transform: translate(0%, -40%);
    transform: translate(0%, -40%); }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%); }
  75% {
    -webkit-transform: translate(100%, 0%);
    transform: translate(100%, 0%); }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%); }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%); } }

@-webkit-keyframes ld-bounce-ltr {
  0% {
    -webkit-transform: translate(-160%, -40%);
    transform: translate(-160%, -40%); }
  12.5% {
    -webkit-transform: translate(-120%, -23%);
    transform: translate(-120%, -23%); }
  25% {
    -webkit-transform: translate(-100%, 0%);
    transform: translate(-100%, 0%); }
  37.5% {
    -webkit-transform: translate(-50%, -23%);
    transform: translate(-50%, -23%); }
  50% {
    -webkit-transform: translate(0%, -40%);
    transform: translate(0%, -40%); }
  62.5% {
    -webkit-transform: translate(50%, -23%);
    transform: translate(50%, -23%); }
  75% {
    -webkit-transform: translate(100%, 0%);
    transform: translate(100%, 0%); }
  87.5% {
    -webkit-transform: translate(120%, -23%);
    transform: translate(120%, -23%); }
  100% {
    -webkit-transform: translate(160%, -40%);
    transform: translate(160%, -40%); } }

@keyframes ld-bounce-a-px {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%); }
  25% {
    -webkit-transform: translate(10%, 0%);
    transform: translate(10%, 0%); }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%); }
  50% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%); }
  75% {
    -webkit-transform: translate(-10%, 0%);
    transform: translate(-10%, 0%); }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%); }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); } }

@-webkit-keyframes ld-bounce-a-px {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  12.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%); }
  25% {
    -webkit-transform: translate(10%, 0%);
    transform: translate(10%, 0%); }
  37.5% {
    -webkit-transform: translate(5%, -28%);
    transform: translate(5%, -28%); }
  50% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  62.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%); }
  75% {
    -webkit-transform: translate(-10%, 0%);
    transform: translate(-10%, 0%); }
  87.5% {
    -webkit-transform: translate(-5%, -28%);
    transform: translate(-5%, -28%); }
  100% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); } }

@keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: linear; }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); } }

@-webkit-keyframes ld-float {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: linear; }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); }
  30% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  50% {
    -webkit-transform: translate(0, -10%);
    transform: translate(0, -10%);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); } }

@keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0); }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg); } }

@-webkit-keyframes ld-hit {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0); }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  100% {
    -webkit-transform: scale(1) translate(0, 200%) skewX(20deg);
    transform: scale(1) translate(0, 200%) skewX(20deg); } }

@keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg); }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg); }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg); }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg); }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg); }
  100% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); } }

@-webkit-keyframes ld-jelly {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  16.6% {
    -webkit-transform: translate(-30%, 0) skewX(30deg);
    transform: translate(-30%, 0) skewX(30deg); }
  33.3% {
    -webkit-transform: translate(25%, 0) skewX(-20deg);
    transform: translate(25%, 0) skewX(-20deg); }
  50% {
    -webkit-transform: translate(-12%, 0) skewX(10deg);
    transform: translate(-12%, 0) skewX(10deg); }
  66.6% {
    -webkit-transform: translate(6%, 0) skewX(-5deg);
    transform: translate(6%, 0) skewX(-5deg); }
  83.3% {
    -webkit-transform: translate(-2.5%, 0) skewX(2deg);
    transform: translate(-2.5%, 0) skewX(2deg); }
  100% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); } }

@keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out; }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in; }
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%); }
  28% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%); }
  48% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%); }
  64% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%); }
  76% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%); }
  86% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%); }
  93% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%); }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); } }

@-webkit-keyframes ld-jump {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out; }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in; }
  0% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  14% {
    -webkit-transform: translateY(-27%);
    transform: translateY(-27%); }
  28% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  38% {
    -webkit-transform: translateY(-20%);
    transform: translateY(-20%); }
  48% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  56% {
    -webkit-transform: translateY(-16%);
    transform: translateY(-16%); }
  64% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  70% {
    -webkit-transform: translateY(-12%);
    transform: translateY(-12%); }
  76% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  81% {
    -webkit-transform: translateY(-7.5%);
    transform: translateY(-7.5%); }
  86% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  90% {
    -webkit-transform: translateY(-3%);
    transform: translateY(-3%); }
  93% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); }
  97% {
    -webkit-transform: translateY(-1.5%);
    transform: translateY(-1.5%); }
  100% {
    -webkit-transform: translateY(0%);
    transform: translateY(0%); } }

@keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0%) rotate(0deg);
    transform: translate(30%, 0%) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg); }
  25% {
    -webkit-transform: translate(0%, 30%) rotate(90deg);
    transform: translate(0%, 30%) rotate(90deg); }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg); }
  50% {
    -webkit-transform: translate(-30%, 0%) rotate(180deg);
    transform: translate(-30%, 0%) rotate(180deg); }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg); }
  75% {
    -webkit-transform: translate(0%, -30%) rotate(270deg);
    transform: translate(0%, -30%) rotate(270deg); }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg); }
  100% {
    -webkit-transform: translate(30%, 0%) rotate(360deg);
    transform: translate(30%, 0%) rotate(360deg); } }

@-webkit-keyframes ld-orbit {
  0% {
    -webkit-transform: translate(30%, 0%) rotate(0deg);
    transform: translate(30%, 0%) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(21%, 21%) rotate(45deg);
    transform: translate(21%, 21%) rotate(45deg); }
  25% {
    -webkit-transform: translate(0%, 30%) rotate(90deg);
    transform: translate(0%, 30%) rotate(90deg); }
  37.5% {
    -webkit-transform: translate(-21%, 21%) rotate(135deg);
    transform: translate(-21%, 21%) rotate(135deg); }
  50% {
    -webkit-transform: translate(-30%, 0%) rotate(180deg);
    transform: translate(-30%, 0%) rotate(180deg); }
  62.5% {
    -webkit-transform: translate(-21%, -21%) rotate(225deg);
    transform: translate(-21%, -21%) rotate(225deg); }
  75% {
    -webkit-transform: translate(0%, -30%) rotate(270deg);
    transform: translate(0%, -30%) rotate(270deg); }
  87.5% {
    -webkit-transform: translate(21%, -21%) rotate(315deg);
    transform: translate(21%, -21%) rotate(315deg); }
  100% {
    -webkit-transform: translate(30%, 0%) rotate(360deg);
    transform: translate(30%, 0%) rotate(360deg); } }

@keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg); }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg); }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg); }
  80% {
    -webkit-transform: translate(0%, 0) skewX(0deg);
    transform: translate(0%, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg); } }

@-webkit-keyframes ld-rush-rtl {
  0% {
    -webkit-transform: translate(200%, 0) skewX(-45deg);
    transform: translate(200%, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(-40%, 0) skewX(35deg);
    transform: translate(-40%, 0) skewX(35deg); }
  45% {
    -webkit-transform: translate(20%, 0) skewX(-15deg);
    transform: translate(20%, 0) skewX(-15deg); }
  60% {
    -webkit-transform: translate(-10%, 0) skewX(7deg);
    transform: translate(-10%, 0) skewX(7deg); }
  80% {
    -webkit-transform: translate(0%, 0) skewX(0deg);
    transform: translate(0%, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(-250%, 0) skewX(-45deg);
    transform: translate(-250%, 0) skewX(-45deg); } }

@keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg); }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg); }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg); }
  80% {
    -webkit-transform: translate(0%, 0) skewX(0deg);
    transform: translate(0%, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg); } }

@-webkit-keyframes ld-rush-ltr {
  0% {
    -webkit-transform: translate(-200%, 0) skewX(45deg);
    transform: translate(-200%, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(40%, 0) skewX(-35deg);
    transform: translate(40%, 0) skewX(-35deg); }
  45% {
    -webkit-transform: translate(-20%, 0) skewX(15deg);
    transform: translate(-20%, 0) skewX(15deg); }
  60% {
    -webkit-transform: translate(10%, 0) skewX(-7deg);
    transform: translate(10%, 0) skewX(-7deg); }
  80% {
    -webkit-transform: translate(0%, 0) skewX(0deg);
    transform: translate(0%, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(250%, 0) skewX(45deg);
    transform: translate(250%, 0) skewX(45deg); } }

@keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0); }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0); }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0); }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-shake {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  16.6% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); }
  33.3% {
    -webkit-transform: translate(25%, 0);
    transform: translate(25%, 0); }
  50% {
    -webkit-transform: translate(-12%, 0);
    transform: translate(-12%, 0); }
  66.6% {
    -webkit-transform: translate(6%, 0);
    transform: translate(6%, 0); }
  83.3% {
    -webkit-transform: translate(-2.5%, 0);
    transform: translate(-2.5%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0); }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0); }
  50% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0); }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(-200%, 0);
    transform: translate(-200%, 0); }
  50% {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%); }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%); }
  50% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, 200%);
    transform: translate(0, 200%); }
  50% {
    -webkit-transform: translate(0, -200%);
    transform: translate(0, -200%); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-tremble {
  0% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%); }
  5% {
    -webkit-transform: translate(0%, 1%);
    transform: translate(0%, 1%); }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%); }
  20% {
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%); }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%); }
  35% {
    -webkit-transform: translate(0%, 1%);
    transform: translate(0%, 1%); }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%); }
  45% {
    -webkit-transform: translate(1%, 0%);
    transform: translate(1%, 0%); }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%); }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%); }
  65% {
    -webkit-transform: translate(0%, 2%);
    transform: translate(0%, 2%); }
  70% {
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%); }
  75% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%); }
  85% {
    -webkit-transform: translate(1%, 0%);
    transform: translate(1%, 0%); }
  90% {
    -webkit-transform: translate(0%, 2%);
    transform: translate(0%, 2%); }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%); } }

@-webkit-keyframes ld-tremble {
  0% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%); }
  5% {
    -webkit-transform: translate(0%, 1%);
    transform: translate(0%, 1%); }
  10% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  15% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%); }
  20% {
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%); }
  25% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  30% {
    -webkit-transform: translate(1%, 3%);
    transform: translate(1%, 3%); }
  35% {
    -webkit-transform: translate(0%, 1%);
    transform: translate(0%, 1%); }
  40% {
    -webkit-transform: translate(1%, 1%);
    transform: translate(1%, 1%); }
  45% {
    -webkit-transform: translate(1%, 0%);
    transform: translate(1%, 0%); }
  50% {
    -webkit-transform: translate(2%, 1%);
    transform: translate(2%, 1%); }
  55% {
    -webkit-transform: translate(1%, 2%);
    transform: translate(1%, 2%); }
  60% {
    -webkit-transform: translate(3%, 1%);
    transform: translate(3%, 1%); }
  65% {
    -webkit-transform: translate(0%, 2%);
    transform: translate(0%, 2%); }
  70% {
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%); }
  75% {
    -webkit-transform: translate(0%, 0%);
    transform: translate(0%, 0%); }
  80% {
    -webkit-transform: translate(2%, 3%);
    transform: translate(2%, 3%); }
  85% {
    -webkit-transform: translate(1%, 0%);
    transform: translate(1%, 0%); }
  90% {
    -webkit-transform: translate(0%, 2%);
    transform: translate(0%, 2%); }
  95% {
    -webkit-transform: translate(3%, 2%);
    transform: translate(3%, 2%); } }

@keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0); }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); } }

@-webkit-keyframes ld-wander-h {
  0% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); }
  50% {
    -webkit-transform: translate(35%, 0);
    transform: translate(35%, 0); }
  100% {
    -webkit-transform: translate(-35%, 0);
    transform: translate(-35%, 0); } }

@keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%); }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%); }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%); } }

@-webkit-keyframes ld-wander-v {
  0% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%); }
  50% {
    -webkit-transform: translate(0, 35%);
    transform: translate(0, 35%); }
  100% {
    -webkit-transform: translate(0, -35%);
    transform: translate(0, -35%); } }

@keyframes ld-jingle {
  0% {
    -webkit-transform: translate(0, -40%) rotate(0deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(0deg) translate(0, 40%); }
  4% {
    -webkit-transform: translate(0, -40%) rotate(11deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(11deg) translate(0, 40%); }
  10% {
    -webkit-transform: translate(0, -40%) rotate(15deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(15deg) translate(0, 40%); }
  18% {
    -webkit-transform: translate(0, -40%) rotate(-11deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-11deg) translate(0, 40%); }
  20% {
    -webkit-transform: translate(0, -40%) rotate(-13deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-13deg) translate(0, 40%); }
  21% {
    -webkit-transform: translate(0, -40%) rotate(-12deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-12deg) translate(0, 40%); }
  22% {
    -webkit-transform: translate(0, -40%) rotate(-10deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-10deg) translate(0, 40%); }
  24% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  26% {
    -webkit-transform: translate(0, -40%) rotate(3deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(3deg) translate(0, 40%); }
  28% {
    -webkit-transform: translate(0, -40%) rotate(9deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(9deg) translate(0, 40%); }
  30% {
    -webkit-transform: translate(0, -40%) rotate(10deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(10deg) translate(0, 40%); }
  31% {
    -webkit-transform: translate(0, -40%) rotate(9deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(9deg) translate(0, 40%); }
  33% {
    -webkit-transform: translate(0, -40%) rotate(5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(5deg) translate(0, 40%); }
  34% {
    -webkit-transform: translate(0, -40%) rotate(1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(1deg) translate(0, 40%); }
  36% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  39% {
    -webkit-transform: translate(0, -40%) rotate(-8deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-8deg) translate(0, 40%); }
  40% {
    -webkit-transform: translate(0, -40%) rotate(-7deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-7deg) translate(0, 40%); }
  44% {
    -webkit-transform: translate(0, -40%) rotate(3deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(3deg) translate(0, 40%); }
  47% {
    -webkit-transform: translate(0, -40%) rotate(7deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(7deg) translate(0, 40%); }
  56% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  63% {
    -webkit-transform: translate(0, -40%) rotate(1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(1deg) translate(0, 40%); }
  75% {
    -webkit-transform: translate(0, -40%) rotate(-1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-1deg) translate(0, 40%); }
  100% {
    -webkit-transform: translate(0, -40%) rotate(0deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(0deg) translate(0, 40%); } }

@-webkit-keyframes ld-jingle {
  0% {
    -webkit-transform: translate(0, -40%) rotate(0deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(0deg) translate(0, 40%); }
  4% {
    -webkit-transform: translate(0, -40%) rotate(11deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(11deg) translate(0, 40%); }
  10% {
    -webkit-transform: translate(0, -40%) rotate(15deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(15deg) translate(0, 40%); }
  18% {
    -webkit-transform: translate(0, -40%) rotate(-11deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-11deg) translate(0, 40%); }
  20% {
    -webkit-transform: translate(0, -40%) rotate(-13deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-13deg) translate(0, 40%); }
  21% {
    -webkit-transform: translate(0, -40%) rotate(-12deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-12deg) translate(0, 40%); }
  22% {
    -webkit-transform: translate(0, -40%) rotate(-10deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-10deg) translate(0, 40%); }
  24% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  26% {
    -webkit-transform: translate(0, -40%) rotate(3deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(3deg) translate(0, 40%); }
  28% {
    -webkit-transform: translate(0, -40%) rotate(9deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(9deg) translate(0, 40%); }
  30% {
    -webkit-transform: translate(0, -40%) rotate(10deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(10deg) translate(0, 40%); }
  31% {
    -webkit-transform: translate(0, -40%) rotate(9deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(9deg) translate(0, 40%); }
  33% {
    -webkit-transform: translate(0, -40%) rotate(5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(5deg) translate(0, 40%); }
  34% {
    -webkit-transform: translate(0, -40%) rotate(1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(1deg) translate(0, 40%); }
  36% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  39% {
    -webkit-transform: translate(0, -40%) rotate(-8deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-8deg) translate(0, 40%); }
  40% {
    -webkit-transform: translate(0, -40%) rotate(-7deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-7deg) translate(0, 40%); }
  44% {
    -webkit-transform: translate(0, -40%) rotate(3deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(3deg) translate(0, 40%); }
  47% {
    -webkit-transform: translate(0, -40%) rotate(7deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(7deg) translate(0, 40%); }
  56% {
    -webkit-transform: translate(0, -40%) rotate(-5deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-5deg) translate(0, 40%); }
  63% {
    -webkit-transform: translate(0, -40%) rotate(1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(1deg) translate(0, 40%); }
  75% {
    -webkit-transform: translate(0, -40%) rotate(-1deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(-1deg) translate(0, 40%); }
  100% {
    -webkit-transform: translate(0, -40%) rotate(0deg) translate(0, 40%);
    transform: translate(0, -40%) rotate(0deg) translate(0, 40%); } }

@keyframes ld-swim {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(5%, -10%) rotate(3deg);
    transform: translate(5%, -10%) rotate(3deg); }
  25% {
    -webkit-transform: translate(0, -15%) rotate(6deg);
    transform: translate(0, -15%) rotate(6deg); }
  37.5% {
    -webkit-transform: translate(-5%, -10%) rotate(3deg);
    transform: translate(-5%, -10%) rotate(3deg); }
  50% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  62.5% {
    -webkit-transform: translate(5%, 10%) rotate(-3deg);
    transform: translate(5%, 10%) rotate(-3deg); }
  75% {
    -webkit-transform: translate(0, 15%) rotate(-6deg);
    transform: translate(0, 15%) rotate(-6deg); }
  87.5% {
    -webkit-transform: translate(-5%, 10%) rotate(-3deg);
    transform: translate(-5%, 10%) rotate(-3deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); } }

@-webkit-keyframes ld-swim {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(5%, -10%) rotate(3deg);
    transform: translate(5%, -10%) rotate(3deg); }
  25% {
    -webkit-transform: translate(0, -15%) rotate(6deg);
    transform: translate(0, -15%) rotate(6deg); }
  37.5% {
    -webkit-transform: translate(-5%, -10%) rotate(3deg);
    transform: translate(-5%, -10%) rotate(3deg); }
  50% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  62.5% {
    -webkit-transform: translate(5%, 10%) rotate(-3deg);
    transform: translate(5%, 10%) rotate(-3deg); }
  75% {
    -webkit-transform: translate(0, 15%) rotate(-6deg);
    transform: translate(0, 15%) rotate(-6deg); }
  87.5% {
    -webkit-transform: translate(-5%, 10%) rotate(-3deg);
    transform: translate(-5%, 10%) rotate(-3deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); } }

@keyframes ld-leaf {
  0% {
    -webkit-transform: translate(-14.7%, -117%) rotate(-0.3deg);
    transform: translate(-14.7%, -117%) rotate(-0.3deg); }
  1% {
    -webkit-transform: translate(-14.4%, -112.5%) rotate(-0.6deg);
    transform: translate(-14.4%, -112.5%) rotate(-0.6deg); }
  2% {
    -webkit-transform: translate(-13.8%, -102%) rotate(-1.2deg);
    transform: translate(-13.8%, -102%) rotate(-1.2deg); }
  3% {
    -webkit-transform: translate(-13.5%, -100.5%) rotate(-1.5deg);
    transform: translate(-13.5%, -100.5%) rotate(-1.5deg); }
  5% {
    -webkit-transform: translate(-7.2%, -87%) rotate(-7.8deg);
    transform: translate(-7.2%, -87%) rotate(-7.8deg); }
  7% {
    -webkit-transform: translate(5.4%, -79.5%) rotate(-20.4deg);
    transform: translate(5.4%, -79.5%) rotate(-20.4deg); }
  10% {
    -webkit-transform: translate(12.9%, -76.5%) rotate(-27.9deg);
    transform: translate(12.9%, -76.5%) rotate(-27.9deg); }
  12% {
    -webkit-transform: translate(14.4%, -75%) rotate(-29.4deg);
    transform: translate(14.4%, -75%) rotate(-29.4deg); }
  13% {
    -webkit-transform: translate(14.7%, -75%) rotate(-29.7deg);
    transform: translate(14.7%, -75%) rotate(-29.7deg); }
  14% {
    -webkit-transform: translate(15%, -75%) rotate(-30deg);
    transform: translate(15%, -75%) rotate(-30deg); }
  15% {
    -webkit-transform: translate(14.4%, -69%) rotate(0.6deg);
    transform: translate(14.4%, -69%) rotate(0.6deg); }
  16% {
    -webkit-transform: translate(13.8%, -58.5%) rotate(1.2deg);
    transform: translate(13.8%, -58.5%) rotate(1.2deg); }
  19% {
    -webkit-transform: translate(7.2%, -45%) rotate(7.8deg);
    transform: translate(7.2%, -45%) rotate(7.8deg); }
  21% {
    -webkit-transform: translate(-5.4%, -37.5%) rotate(20.4deg);
    transform: translate(-5.4%, -37.5%) rotate(20.4deg); }
  24% {
    -webkit-transform: translate(-12.9%, -33%) rotate(27.9deg);
    transform: translate(-12.9%, -33%) rotate(27.9deg); }
  26% {
    -webkit-transform: translate(-14.4%, -33%) rotate(29.4deg);
    transform: translate(-14.4%, -33%) rotate(29.4deg); }
  27% {
    -webkit-transform: translate(-14.7%, -31.5%) rotate(29.7deg);
    transform: translate(-14.7%, -31.5%) rotate(29.7deg); }
  28% {
    -webkit-transform: translate(-15%, -31.5%) rotate(30deg);
    transform: translate(-15%, -31.5%) rotate(30deg); }
  29% {
    -webkit-transform: translate(-14.4%, -27%) rotate(-0.6deg);
    transform: translate(-14.4%, -27%) rotate(-0.6deg); }
  30% {
    -webkit-transform: translate(-13.8%, -16.5%) rotate(-1.2deg);
    transform: translate(-13.8%, -16.5%) rotate(-1.2deg); }
  31% {
    -webkit-transform: translate(-13.5%, -15%) rotate(-1.5deg);
    transform: translate(-13.5%, -15%) rotate(-1.5deg); }
  33% {
    -webkit-transform: translate(-7.2%, -1.5%) rotate(-7.8deg);
    transform: translate(-7.2%, -1.5%) rotate(-7.8deg); }
  36% {
    -webkit-transform: translate(5.4%, 4.5%) rotate(-20.4deg);
    transform: translate(5.4%, 4.5%) rotate(-20.4deg); }
  38% {
    -webkit-transform: translate(12.9%, 9%) rotate(-27.9deg);
    transform: translate(12.9%, 9%) rotate(-27.9deg); }
  40% {
    -webkit-transform: translate(14.4%, 10.5%) rotate(-29.4deg);
    transform: translate(14.4%, 10.5%) rotate(-29.4deg); }
  41% {
    -webkit-transform: translate(14.7%, 10.5%) rotate(-29.7deg);
    transform: translate(14.7%, 10.5%) rotate(-29.7deg); }
  42% {
    -webkit-transform: translate(15%, 10.5%) rotate(-30deg);
    transform: translate(15%, 10.5%) rotate(-30deg); }
  43% {
    -webkit-transform: translate(15%, 10.5%) rotate(-30deg);
    transform: translate(15%, 10.5%) rotate(-30deg); }
  43% {
    -webkit-transform: translate(14.7%, 10.5%) rotate(0.3deg);
    transform: translate(14.7%, 10.5%) rotate(0.3deg); }
  43% {
    -webkit-transform: translate(14.4%, 16.5%) rotate(0.6deg);
    transform: translate(14.4%, 16.5%) rotate(0.6deg); }
  45% {
    -webkit-transform: translate(13.8%, 25.5%) rotate(1.2deg);
    transform: translate(13.8%, 25.5%) rotate(1.2deg); }
  45% {
    -webkit-transform: translate(13.5%, 27%) rotate(1.5deg);
    transform: translate(13.5%, 27%) rotate(1.5deg); }
  48% {
    -webkit-transform: translate(7.2%, 40.5%) rotate(7.8deg);
    transform: translate(7.2%, 40.5%) rotate(7.8deg); }
  50% {
    -webkit-transform: translate(-5.4%, 48%) rotate(20.4deg);
    transform: translate(-5.4%, 48%) rotate(20.4deg); }
  52% {
    -webkit-transform: translate(-12.9%, 51%) rotate(27.9deg);
    transform: translate(-12.9%, 51%) rotate(27.9deg); }
  54% {
    -webkit-transform: translate(-14.4%, 52.5%) rotate(29.4deg);
    transform: translate(-14.4%, 52.5%) rotate(29.4deg); }
  56% {
    -webkit-transform: translate(-14.7%, 54%) rotate(29.7deg);
    transform: translate(-14.7%, 54%) rotate(29.7deg); }
  57% {
    -webkit-transform: translate(-14.7%, 54%) rotate(-0.3deg);
    transform: translate(-14.7%, 54%) rotate(-0.3deg); }
  58% {
    -webkit-transform: translate(-14.4%, 58.5%) rotate(-0.6deg);
    transform: translate(-14.4%, 58.5%) rotate(-0.6deg); }
  59% {
    -webkit-transform: translate(-13.5%, 70.5%) rotate(-1.5deg);
    transform: translate(-13.5%, 70.5%) rotate(-1.5deg); }
  62% {
    -webkit-transform: translate(-7.2%, 84%) rotate(-7.8deg);
    transform: translate(-7.2%, 84%) rotate(-7.8deg); }
  64% {
    -webkit-transform: translate(5.4%, 91.5%) rotate(-20.4deg);
    transform: translate(5.4%, 91.5%) rotate(-20.4deg); }
  67% {
    -webkit-transform: translate(12.9%, 94.5%) rotate(-27.9deg);
    transform: translate(12.9%, 94.5%) rotate(-27.9deg); }
  69% {
    -webkit-transform: translate(14.4%, 96%) rotate(-29.4deg);
    transform: translate(14.4%, 96%) rotate(-29.4deg); }
  70% {
    -webkit-transform: translate(14.7%, 96%) rotate(-29.7deg);
    transform: translate(14.7%, 96%) rotate(-29.7deg); }
  71% {
    -webkit-transform: translate(15%, 96%) rotate(-30deg);
    transform: translate(15%, 96%) rotate(-30deg); }
  72% {
    -webkit-transform: translate(14.4%, 102%) rotate(0.6deg);
    transform: translate(14.4%, 102%) rotate(0.6deg); }
  73% {
    -webkit-transform: translate(13.8%, 111%) rotate(1.2deg);
    transform: translate(13.8%, 111%) rotate(1.2deg); }
  74% {
    -webkit-transform: translate(13.5%, 112.5%) rotate(1.5deg);
    transform: translate(13.5%, 112.5%) rotate(1.5deg); }
  76% {
    -webkit-transform: translate(7.2%, 126%) rotate(7.8deg);
    transform: translate(7.2%, 126%) rotate(7.8deg); }
  79% {
    -webkit-transform: translate(-5.4%, 133.5%) rotate(20.4deg);
    transform: translate(-5.4%, 133.5%) rotate(20.4deg); }
  81% {
    -webkit-transform: translate(-12.9%, 138%) rotate(27.9deg);
    transform: translate(-12.9%, 138%) rotate(27.9deg); }
  83% {
    -webkit-transform: translate(-14.4%, 139.5%) rotate(29.4deg);
    transform: translate(-14.4%, 139.5%) rotate(29.4deg); }
  84% {
    -webkit-transform: translate(-14.7%, 139.5%) rotate(29.7deg);
    transform: translate(-14.7%, 139.5%) rotate(29.7deg); }
  85% {
    -webkit-transform: translate(-15%, 139.5%) rotate(30deg);
    transform: translate(-15%, 139.5%) rotate(30deg); }
  86% {
    -webkit-transform: translate(-14.7%, 139.5%) rotate(-0.3deg);
    transform: translate(-14.7%, 139.5%) rotate(-0.3deg); }
  86% {
    -webkit-transform: translate(-14.4%, 144%) rotate(-0.6deg);
    transform: translate(-14.4%, 144%) rotate(-0.6deg); }
  88% {
    -webkit-transform: translate(-13.5%, 156%) rotate(-1.5deg);
    transform: translate(-13.5%, 156%) rotate(-1.5deg); }
  90% {
    -webkit-transform: translate(-7.2%, 169.5%) rotate(-7.8deg);
    transform: translate(-7.2%, 169.5%) rotate(-7.8deg); }
  93% {
    -webkit-transform: translate(5.4%, 177%) rotate(-20.4deg);
    transform: translate(5.4%, 177%) rotate(-20.4deg); }
  95% {
    -webkit-transform: translate(12.9%, 180%) rotate(-27.9deg);
    transform: translate(12.9%, 180%) rotate(-27.9deg); }
  97% {
    -webkit-transform: translate(14.4%, 181.5%) rotate(-29.4deg);
    transform: translate(14.4%, 181.5%) rotate(-29.4deg); }
  99% {
    -webkit-transform: translate(14.7%, 181.5%) rotate(-29.7deg);
    transform: translate(14.7%, 181.5%) rotate(-29.7deg); }
  100% {
    -webkit-transform: translate(15%, 181.5%) rotate(-30deg);
    transform: translate(15%, 181.5%) rotate(-30deg); } }

@-webkit-keyframes ld-leaf {
  0% {
    -webkit-transform: translate(-14.7%, -117%) rotate(-0.3deg);
    transform: translate(-14.7%, -117%) rotate(-0.3deg); }
  1% {
    -webkit-transform: translate(-14.4%, -112.5%) rotate(-0.6deg);
    transform: translate(-14.4%, -112.5%) rotate(-0.6deg); }
  2% {
    -webkit-transform: translate(-13.8%, -102%) rotate(-1.2deg);
    transform: translate(-13.8%, -102%) rotate(-1.2deg); }
  3% {
    -webkit-transform: translate(-13.5%, -100.5%) rotate(-1.5deg);
    transform: translate(-13.5%, -100.5%) rotate(-1.5deg); }
  5% {
    -webkit-transform: translate(-7.2%, -87%) rotate(-7.8deg);
    transform: translate(-7.2%, -87%) rotate(-7.8deg); }
  7% {
    -webkit-transform: translate(5.4%, -79.5%) rotate(-20.4deg);
    transform: translate(5.4%, -79.5%) rotate(-20.4deg); }
  10% {
    -webkit-transform: translate(12.9%, -76.5%) rotate(-27.9deg);
    transform: translate(12.9%, -76.5%) rotate(-27.9deg); }
  12% {
    -webkit-transform: translate(14.4%, -75%) rotate(-29.4deg);
    transform: translate(14.4%, -75%) rotate(-29.4deg); }
  13% {
    -webkit-transform: translate(14.7%, -75%) rotate(-29.7deg);
    transform: translate(14.7%, -75%) rotate(-29.7deg); }
  14% {
    -webkit-transform: translate(15%, -75%) rotate(-30deg);
    transform: translate(15%, -75%) rotate(-30deg); }
  15% {
    -webkit-transform: translate(14.4%, -69%) rotate(0.6deg);
    transform: translate(14.4%, -69%) rotate(0.6deg); }
  16% {
    -webkit-transform: translate(13.8%, -58.5%) rotate(1.2deg);
    transform: translate(13.8%, -58.5%) rotate(1.2deg); }
  19% {
    -webkit-transform: translate(7.2%, -45%) rotate(7.8deg);
    transform: translate(7.2%, -45%) rotate(7.8deg); }
  21% {
    -webkit-transform: translate(-5.4%, -37.5%) rotate(20.4deg);
    transform: translate(-5.4%, -37.5%) rotate(20.4deg); }
  24% {
    -webkit-transform: translate(-12.9%, -33%) rotate(27.9deg);
    transform: translate(-12.9%, -33%) rotate(27.9deg); }
  26% {
    -webkit-transform: translate(-14.4%, -33%) rotate(29.4deg);
    transform: translate(-14.4%, -33%) rotate(29.4deg); }
  27% {
    -webkit-transform: translate(-14.7%, -31.5%) rotate(29.7deg);
    transform: translate(-14.7%, -31.5%) rotate(29.7deg); }
  28% {
    -webkit-transform: translate(-15%, -31.5%) rotate(30deg);
    transform: translate(-15%, -31.5%) rotate(30deg); }
  29% {
    -webkit-transform: translate(-14.4%, -27%) rotate(-0.6deg);
    transform: translate(-14.4%, -27%) rotate(-0.6deg); }
  30% {
    -webkit-transform: translate(-13.8%, -16.5%) rotate(-1.2deg);
    transform: translate(-13.8%, -16.5%) rotate(-1.2deg); }
  31% {
    -webkit-transform: translate(-13.5%, -15%) rotate(-1.5deg);
    transform: translate(-13.5%, -15%) rotate(-1.5deg); }
  33% {
    -webkit-transform: translate(-7.2%, -1.5%) rotate(-7.8deg);
    transform: translate(-7.2%, -1.5%) rotate(-7.8deg); }
  36% {
    -webkit-transform: translate(5.4%, 4.5%) rotate(-20.4deg);
    transform: translate(5.4%, 4.5%) rotate(-20.4deg); }
  38% {
    -webkit-transform: translate(12.9%, 9%) rotate(-27.9deg);
    transform: translate(12.9%, 9%) rotate(-27.9deg); }
  40% {
    -webkit-transform: translate(14.4%, 10.5%) rotate(-29.4deg);
    transform: translate(14.4%, 10.5%) rotate(-29.4deg); }
  41% {
    -webkit-transform: translate(14.7%, 10.5%) rotate(-29.7deg);
    transform: translate(14.7%, 10.5%) rotate(-29.7deg); }
  42% {
    -webkit-transform: translate(15%, 10.5%) rotate(-30deg);
    transform: translate(15%, 10.5%) rotate(-30deg); }
  43% {
    -webkit-transform: translate(15%, 10.5%) rotate(-30deg);
    transform: translate(15%, 10.5%) rotate(-30deg); }
  43% {
    -webkit-transform: translate(14.7%, 10.5%) rotate(0.3deg);
    transform: translate(14.7%, 10.5%) rotate(0.3deg); }
  43% {
    -webkit-transform: translate(14.4%, 16.5%) rotate(0.6deg);
    transform: translate(14.4%, 16.5%) rotate(0.6deg); }
  45% {
    -webkit-transform: translate(13.8%, 25.5%) rotate(1.2deg);
    transform: translate(13.8%, 25.5%) rotate(1.2deg); }
  45% {
    -webkit-transform: translate(13.5%, 27%) rotate(1.5deg);
    transform: translate(13.5%, 27%) rotate(1.5deg); }
  48% {
    -webkit-transform: translate(7.2%, 40.5%) rotate(7.8deg);
    transform: translate(7.2%, 40.5%) rotate(7.8deg); }
  50% {
    -webkit-transform: translate(-5.4%, 48%) rotate(20.4deg);
    transform: translate(-5.4%, 48%) rotate(20.4deg); }
  52% {
    -webkit-transform: translate(-12.9%, 51%) rotate(27.9deg);
    transform: translate(-12.9%, 51%) rotate(27.9deg); }
  54% {
    -webkit-transform: translate(-14.4%, 52.5%) rotate(29.4deg);
    transform: translate(-14.4%, 52.5%) rotate(29.4deg); }
  56% {
    -webkit-transform: translate(-14.7%, 54%) rotate(29.7deg);
    transform: translate(-14.7%, 54%) rotate(29.7deg); }
  57% {
    -webkit-transform: translate(-14.7%, 54%) rotate(-0.3deg);
    transform: translate(-14.7%, 54%) rotate(-0.3deg); }
  58% {
    -webkit-transform: translate(-14.4%, 58.5%) rotate(-0.6deg);
    transform: translate(-14.4%, 58.5%) rotate(-0.6deg); }
  59% {
    -webkit-transform: translate(-13.5%, 70.5%) rotate(-1.5deg);
    transform: translate(-13.5%, 70.5%) rotate(-1.5deg); }
  62% {
    -webkit-transform: translate(-7.2%, 84%) rotate(-7.8deg);
    transform: translate(-7.2%, 84%) rotate(-7.8deg); }
  64% {
    -webkit-transform: translate(5.4%, 91.5%) rotate(-20.4deg);
    transform: translate(5.4%, 91.5%) rotate(-20.4deg); }
  67% {
    -webkit-transform: translate(12.9%, 94.5%) rotate(-27.9deg);
    transform: translate(12.9%, 94.5%) rotate(-27.9deg); }
  69% {
    -webkit-transform: translate(14.4%, 96%) rotate(-29.4deg);
    transform: translate(14.4%, 96%) rotate(-29.4deg); }
  70% {
    -webkit-transform: translate(14.7%, 96%) rotate(-29.7deg);
    transform: translate(14.7%, 96%) rotate(-29.7deg); }
  71% {
    -webkit-transform: translate(15%, 96%) rotate(-30deg);
    transform: translate(15%, 96%) rotate(-30deg); }
  72% {
    -webkit-transform: translate(14.4%, 102%) rotate(0.6deg);
    transform: translate(14.4%, 102%) rotate(0.6deg); }
  73% {
    -webkit-transform: translate(13.8%, 111%) rotate(1.2deg);
    transform: translate(13.8%, 111%) rotate(1.2deg); }
  74% {
    -webkit-transform: translate(13.5%, 112.5%) rotate(1.5deg);
    transform: translate(13.5%, 112.5%) rotate(1.5deg); }
  76% {
    -webkit-transform: translate(7.2%, 126%) rotate(7.8deg);
    transform: translate(7.2%, 126%) rotate(7.8deg); }
  79% {
    -webkit-transform: translate(-5.4%, 133.5%) rotate(20.4deg);
    transform: translate(-5.4%, 133.5%) rotate(20.4deg); }
  81% {
    -webkit-transform: translate(-12.9%, 138%) rotate(27.9deg);
    transform: translate(-12.9%, 138%) rotate(27.9deg); }
  83% {
    -webkit-transform: translate(-14.4%, 139.5%) rotate(29.4deg);
    transform: translate(-14.4%, 139.5%) rotate(29.4deg); }
  84% {
    -webkit-transform: translate(-14.7%, 139.5%) rotate(29.7deg);
    transform: translate(-14.7%, 139.5%) rotate(29.7deg); }
  85% {
    -webkit-transform: translate(-15%, 139.5%) rotate(30deg);
    transform: translate(-15%, 139.5%) rotate(30deg); }
  86% {
    -webkit-transform: translate(-14.7%, 139.5%) rotate(-0.3deg);
    transform: translate(-14.7%, 139.5%) rotate(-0.3deg); }
  86% {
    -webkit-transform: translate(-14.4%, 144%) rotate(-0.6deg);
    transform: translate(-14.4%, 144%) rotate(-0.6deg); }
  88% {
    -webkit-transform: translate(-13.5%, 156%) rotate(-1.5deg);
    transform: translate(-13.5%, 156%) rotate(-1.5deg); }
  90% {
    -webkit-transform: translate(-7.2%, 169.5%) rotate(-7.8deg);
    transform: translate(-7.2%, 169.5%) rotate(-7.8deg); }
  93% {
    -webkit-transform: translate(5.4%, 177%) rotate(-20.4deg);
    transform: translate(5.4%, 177%) rotate(-20.4deg); }
  95% {
    -webkit-transform: translate(12.9%, 180%) rotate(-27.9deg);
    transform: translate(12.9%, 180%) rotate(-27.9deg); }
  97% {
    -webkit-transform: translate(14.4%, 181.5%) rotate(-29.4deg);
    transform: translate(14.4%, 181.5%) rotate(-29.4deg); }
  99% {
    -webkit-transform: translate(14.7%, 181.5%) rotate(-29.7deg);
    transform: translate(14.7%, 181.5%) rotate(-29.7deg); }
  100% {
    -webkit-transform: translate(15%, 181.5%) rotate(-30deg);
    transform: translate(15%, 181.5%) rotate(-30deg); } }

@keyframes ld-slot {
  0% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  9.09% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  9.1% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  16.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  17% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  23.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  23.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  29.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  29.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  34.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  34.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  38.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  38.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  41.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  41.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  44.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  44.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  46.29% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  46.3% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  47.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  47.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  48.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  48.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  49.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  49.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  50.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  50.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  51.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  51.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  52.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  52.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  53.690000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  53.7% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  55.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  55.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  58.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  58.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  61.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  61.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  65.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  65.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  70.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  70.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  76.19% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  76.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  82.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  83% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  90.89% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  90.9% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  99.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  100% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); } }

@-webkit-keyframes ld-slot {
  0% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  9.09% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  9.1% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  16.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  17% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  23.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  23.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  29.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  29.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  34.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  34.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  38.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  38.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  41.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  41.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  44.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  44.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  46.29% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  46.3% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  47.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  47.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  48.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  48.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  49.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.79% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  49.8% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  49.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  50.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  50.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  50.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  51.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  51.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  52.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  52.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  53.690000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  53.7% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  55.59% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  55.6% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  58.190000000000005% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  58.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  61.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  61.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  65.49% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  65.5% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  70.39% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  70.4% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  76.19% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  76.2% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  82.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  83% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  90.89% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  90.9% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); }
  99.99% {
    -webkit-transform: translate(0, 160%);
    transform: translate(0, 160%); }
  100% {
    -webkit-transform: translate(0, -160%);
    transform: translate(0, -160%); } }

@keyframes ld-bounce-px {
  0%, 90% {
    animation-timing-function: linear; }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0, 30px) scaleY(0.5);
    transform: translate(0, 30px) scaleY(0.5); }
  10% {
    -webkit-transform: translate(0, 5px) scaleY(1.1);
    transform: translate(0, 5px) scaleY(1.1); }
  50% {
    -webkit-transform: translate(0, -37px) scaleY(1.1);
    transform: translate(0, -37px) scaleY(1.1); }
  90% {
    -webkit-transform: translate(0, 5px) scaleY(1.1);
    transform: translate(0, 5px) scaleY(1.1); }
  100% {
    -webkit-transform: translate(0, 30px) scaleY(0.5);
    transform: translate(0, 30px) scaleY(0.5); } }

@-webkit-keyframes ld-bounce-px {
  0%, 90% {
    animation-timing-function: linear; }
  10% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  50% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0, 30px) scaleY(0.5);
    transform: translate(0, 30px) scaleY(0.5); }
  10% {
    -webkit-transform: translate(0, 5px) scaleY(1.1);
    transform: translate(0, 5px) scaleY(1.1); }
  50% {
    -webkit-transform: translate(0, -37px) scaleY(1.1);
    transform: translate(0, -37px) scaleY(1.1); }
  90% {
    -webkit-transform: translate(0, 5px) scaleY(1.1);
    transform: translate(0, 5px) scaleY(1.1); }
  100% {
    -webkit-transform: translate(0, 30px) scaleY(0.5);
    transform: translate(0, 30px) scaleY(0.5); } }

@keyframes ld-bounce-px-rtl {
  0% {
    -webkit-transform: translate(80px, -20px);
    transform: translate(80px, -20px); }
  12.5% {
    -webkit-transform: translate(60px, -12px);
    transform: translate(60px, -12px); }
  25% {
    -webkit-transform: translate(40px, 0);
    transform: translate(40px, 0); }
  37.5% {
    -webkit-transform: translate(25px, -12px);
    transform: translate(25px, -12px); }
  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px); }
  62.5% {
    -webkit-transform: translate(-25px, -12px);
    transform: translate(-25px, -12px); }
  75% {
    -webkit-transform: translate(-40px, 0);
    transform: translate(-40px, 0); }
  87.5% {
    -webkit-transform: translate(-60px, -12px);
    transform: translate(-60px, -12px); }
  100% {
    -webkit-transform: translate(-80px, -20px);
    transform: translate(-80px, -20px); } }

@-webkit-keyframes ld-bounce-px-rtl {
  0% {
    -webkit-transform: translate(80px, -20px);
    transform: translate(80px, -20px); }
  12.5% {
    -webkit-transform: translate(60px, -12px);
    transform: translate(60px, -12px); }
  25% {
    -webkit-transform: translate(40px, 0);
    transform: translate(40px, 0); }
  37.5% {
    -webkit-transform: translate(25px, -12px);
    transform: translate(25px, -12px); }
  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px); }
  62.5% {
    -webkit-transform: translate(-25px, -12px);
    transform: translate(-25px, -12px); }
  75% {
    -webkit-transform: translate(-40px, 0);
    transform: translate(-40px, 0); }
  87.5% {
    -webkit-transform: translate(-60px, -12px);
    transform: translate(-60px, -12px); }
  100% {
    -webkit-transform: translate(-80px, -20px);
    transform: translate(-80px, -20px); } }

@keyframes ld-bounce-px-ltr {
  0% {
    -webkit-transform: translate(-80px, -20px);
    transform: translate(-80px, -20px); }
  12.5% {
    -webkit-transform: translate(-60px, -12px);
    transform: translate(-60px, -12px); }
  25% {
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0); }
  37.5% {
    -webkit-transform: translate(-25px, -12px);
    transform: translate(-25px, -12px); }
  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px); }
  62.5% {
    -webkit-transform: translate(25px, -12px);
    transform: translate(25px, -12px); }
  75% {
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0); }
  87.5% {
    -webkit-transform: translate(60px, -12px);
    transform: translate(60px, -12px); }
  100% {
    -webkit-transform: translate(80px, -20px);
    transform: translate(80px, -20px); } }

@-webkit-keyframes ld-bounce-px-ltr {
  0% {
    -webkit-transform: translate(-80px, -20px);
    transform: translate(-80px, -20px); }
  12.5% {
    -webkit-transform: translate(-60px, -12px);
    transform: translate(-60px, -12px); }
  25% {
    -webkit-transform: translate(-50px, 0);
    transform: translate(-50px, 0); }
  37.5% {
    -webkit-transform: translate(-25px, -12px);
    transform: translate(-25px, -12px); }
  50% {
    -webkit-transform: translate(0, -20px);
    transform: translate(0, -20px); }
  62.5% {
    -webkit-transform: translate(25px, -12px);
    transform: translate(25px, -12px); }
  75% {
    -webkit-transform: translate(50px, 0);
    transform: translate(50px, 0); }
  87.5% {
    -webkit-transform: translate(60px, -12px);
    transform: translate(60px, -12px); }
  100% {
    -webkit-transform: translate(80px, -20px);
    transform: translate(80px, -20px); } }

@keyframes ld-bounce-a-px {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  12.5% {
    -webkit-transform: translate(5px, -28px);
    transform: translate(5px, -28px); }
  25% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0); }
  37.5% {
    -webkit-transform: translate(5px, -28px);
    transform: translate(5px, -28px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  62.5% {
    -webkit-transform: translate(-5px, -28px);
    transform: translate(-5px, -28px); }
  75% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0); }
  87.5% {
    -webkit-transform: translate(-5px, -28px);
    transform: translate(-5px, -28px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-bounce-a-px {
  0%, 25%, 50%, 75%, 100% {
    animation-timing-function: cubic-bezier(0, 0.4, 0.6, 1); }
  12.5%, 37.5%, 62.5%, 87.5% {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 0.6); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  12.5% {
    -webkit-transform: translate(5px, -28px);
    transform: translate(5px, -28px); }
  25% {
    -webkit-transform: translate(10px, 0);
    transform: translate(10px, 0); }
  37.5% {
    -webkit-transform: translate(5px, -28px);
    transform: translate(5px, -28px); }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  62.5% {
    -webkit-transform: translate(-5px, -28px);
    transform: translate(-5px, -28px); }
  75% {
    -webkit-transform: translate(-10px, 0);
    transform: translate(-10px, 0); }
  87.5% {
    -webkit-transform: translate(-5px, -28px);
    transform: translate(-5px, -28px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-float-px {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: linear; }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); }
  30% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  50% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); } }

@-webkit-keyframes ld-float-px {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: linear; }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); }
  30% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  50% {
    -webkit-transform: translate(0, -10px);
    transform: translate(0, -10px);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.3); } }

@keyframes ld-hit-px {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0); }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  100% {
    -webkit-transform: scale(1) translate(0, 150px) skewX(20deg);
    transform: scale(1) translate(0, 150px) skewX(20deg); } }

@-webkit-keyframes ld-hit-px {
  0% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    -webkit-transform: scale(0) translate(0, 0) skewX(0);
    transform: scale(0) translate(0, 0) skewX(0); }
  20% {
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  50% {
    animation-timing-function: cubic-bezier(1, 0, 1, 0.5);
    -webkit-transform: scale(1) translate(0, 0) skewX(20deg);
    transform: scale(1) translate(0, 0) skewX(20deg); }
  100% {
    -webkit-transform: scale(1) translate(0, 150px) skewX(20deg);
    transform: scale(1) translate(0, 150px) skewX(20deg); } }

@keyframes ld-jelly-px {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  16.6% {
    -webkit-transform: translate(-30px, 0) skewX(30deg);
    transform: translate(-30px, 0) skewX(30deg); }
  33.3% {
    -webkit-transform: translate(25px, 0) skewX(-20deg);
    transform: translate(25px, 0) skewX(-20deg); }
  50% {
    -webkit-transform: translate(-12px, 0) skewX(10deg);
    transform: translate(-12px, 0) skewX(10deg); }
  66.6% {
    -webkit-transform: translate(6px, 0) skewX(-5deg);
    transform: translate(6px, 0) skewX(-5deg); }
  83.3% {
    -webkit-transform: translate(-2.5px, 0) skewX(2deg);
    transform: translate(-2.5px, 0) skewX(2deg); }
  100% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); } }

@-webkit-keyframes ld-jelly-px {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  16.6% {
    -webkit-transform: translate(-30px, 0) skewX(30deg);
    transform: translate(-30px, 0) skewX(30deg); }
  33.3% {
    -webkit-transform: translate(25px, 0) skewX(-20deg);
    transform: translate(25px, 0) skewX(-20deg); }
  50% {
    -webkit-transform: translate(-12px, 0) skewX(10deg);
    transform: translate(-12px, 0) skewX(10deg); }
  66.6% {
    -webkit-transform: translate(6px, 0) skewX(-5deg);
    transform: translate(6px, 0) skewX(-5deg); }
  83.3% {
    -webkit-transform: translate(-2.5px, 0) skewX(2deg);
    transform: translate(-2.5px, 0) skewX(2deg); }
  100% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); } }

@keyframes ld-jump-px {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out; }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in; }
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  14% {
    -webkit-transform: translateY(-27px);
    transform: translateY(-27px); }
  28% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  38% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  48% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  56% {
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px); }
  64% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  70% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px); }
  76% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  81% {
    -webkit-transform: translateY(-7.5px);
    transform: translateY(-7.5px); }
  86% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  90% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }
  93% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  97% {
    -webkit-transform: translateY(-1.5px);
    transform: translateY(-1.5px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@-webkit-keyframes ld-jump-px {
  0%, 28%, 48%, 64%, 76%, 86%, 93%, 100% {
    animation-timing-function: ease-out; }
  14%, 38%, 56%, 70%, 81%, 90%, 97% {
    animation-timing-function: ease-in; }
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  14% {
    -webkit-transform: translateY(-27px);
    transform: translateY(-27px); }
  28% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  38% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  48% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  56% {
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px); }
  64% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  70% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px); }
  76% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  81% {
    -webkit-transform: translateY(-7.5px);
    transform: translateY(-7.5px); }
  86% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  90% {
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px); }
  93% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  97% {
    -webkit-transform: translateY(-1.5px);
    transform: translateY(-1.5px); }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px); } }

@keyframes ld-orbit-px {
  0% {
    -webkit-transform: translate(30px, 0) rotate(0deg);
    transform: translate(30px, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(21px, 21px) rotate(45deg);
    transform: translate(21px, 21px) rotate(45deg); }
  25% {
    -webkit-transform: translate(0, 30px) rotate(90deg);
    transform: translate(0, 30px) rotate(90deg); }
  37.5% {
    -webkit-transform: translate(-21px, 21px) rotate(135deg);
    transform: translate(-21px, 21px) rotate(135deg); }
  50% {
    -webkit-transform: translate(-30px, 0) rotate(180deg);
    transform: translate(-30px, 0) rotate(180deg); }
  62.5% {
    -webkit-transform: translate(-21px, -21px) rotate(225deg);
    transform: translate(-21px, -21px) rotate(225deg); }
  75% {
    -webkit-transform: translate(0, -30px) rotate(270deg);
    transform: translate(0, -30px) rotate(270deg); }
  87.5% {
    -webkit-transform: translate(21px, -21px) rotate(315deg);
    transform: translate(21px, -21px) rotate(315deg); }
  100% {
    -webkit-transform: translate(30px, 0) rotate(360deg);
    transform: translate(30px, 0) rotate(360deg); } }

@-webkit-keyframes ld-orbit-px {
  0% {
    -webkit-transform: translate(30px, 0) rotate(0deg);
    transform: translate(30px, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(21px, 21px) rotate(45deg);
    transform: translate(21px, 21px) rotate(45deg); }
  25% {
    -webkit-transform: translate(0, 30px) rotate(90deg);
    transform: translate(0, 30px) rotate(90deg); }
  37.5% {
    -webkit-transform: translate(-21px, 21px) rotate(135deg);
    transform: translate(-21px, 21px) rotate(135deg); }
  50% {
    -webkit-transform: translate(-30px, 0) rotate(180deg);
    transform: translate(-30px, 0) rotate(180deg); }
  62.5% {
    -webkit-transform: translate(-21px, -21px) rotate(225deg);
    transform: translate(-21px, -21px) rotate(225deg); }
  75% {
    -webkit-transform: translate(0, -30px) rotate(270deg);
    transform: translate(0, -30px) rotate(270deg); }
  87.5% {
    -webkit-transform: translate(21px, -21px) rotate(315deg);
    transform: translate(21px, -21px) rotate(315deg); }
  100% {
    -webkit-transform: translate(30px, 0) rotate(360deg);
    transform: translate(30px, 0) rotate(360deg); } }

@keyframes ld-rush-px-rtl {
  0% {
    -webkit-transform: translate(100px, 0) skewX(-45deg);
    transform: translate(100px, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(-20px, 0) skewX(35deg);
    transform: translate(-20px, 0) skewX(35deg); }
  45% {
    -webkit-transform: translate(10px, 0) skewX(-15deg);
    transform: translate(10px, 0) skewX(-15deg); }
  60% {
    -webkit-transform: translate(-5px, 0) skewX(7deg);
    transform: translate(-5px, 0) skewX(7deg); }
  80% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(-150px, 0) skewX(-45deg);
    transform: translate(-150px, 0) skewX(-45deg); } }

@-webkit-keyframes ld-rush-px-rtl {
  0% {
    -webkit-transform: translate(100px, 0) skewX(-45deg);
    transform: translate(100px, 0) skewX(-45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(-20px, 0) skewX(35deg);
    transform: translate(-20px, 0) skewX(35deg); }
  45% {
    -webkit-transform: translate(10px, 0) skewX(-15deg);
    transform: translate(10px, 0) skewX(-15deg); }
  60% {
    -webkit-transform: translate(-5px, 0) skewX(7deg);
    transform: translate(-5px, 0) skewX(7deg); }
  80% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(-150px, 0) skewX(-45deg);
    transform: translate(-150px, 0) skewX(-45deg); } }

@keyframes ld-rush-px-ltr {
  0% {
    -webkit-transform: translate(-100px, 0) skewX(45deg);
    transform: translate(-100px, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(20px, 0) skewX(-35deg);
    transform: translate(20px, 0) skewX(-35deg); }
  45% {
    -webkit-transform: translate(-10px, 0) skewX(15deg);
    transform: translate(-10px, 0) skewX(15deg); }
  60% {
    -webkit-transform: translate(5px, 0) skewX(-7deg);
    transform: translate(5px, 0) skewX(-7deg); }
  80% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(150px, 0) skewX(45deg);
    transform: translate(150px, 0) skewX(45deg); } }

@-webkit-keyframes ld-rush-px-ltr {
  0% {
    -webkit-transform: translate(-100px, 0) skewX(45deg);
    transform: translate(-100px, 0) skewX(45deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  30% {
    -webkit-transform: translate(20px, 0) skewX(-35deg);
    transform: translate(20px, 0) skewX(-35deg); }
  45% {
    -webkit-transform: translate(-10px, 0) skewX(15deg);
    transform: translate(-10px, 0) skewX(15deg); }
  60% {
    -webkit-transform: translate(5px, 0) skewX(-7deg);
    transform: translate(5px, 0) skewX(-7deg); }
  80% {
    -webkit-transform: translate(0, 0) skewX(0deg);
    transform: translate(0, 0) skewX(0deg); }
  100% {
    -webkit-transform: translate(150px, 0) skewX(45deg);
    transform: translate(150px, 0) skewX(45deg); } }

@keyframes ld-shake-px {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  16.6% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); }
  33.3% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0); }
  50% {
    -webkit-transform: translate(-12px, 0);
    transform: translate(-12px, 0); }
  66.6% {
    -webkit-transform: translate(6px, 0);
    transform: translate(6px, 0); }
  83.3% {
    -webkit-transform: translate(-2.5px, 0);
    transform: translate(-2.5px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-shake-px {
  0%, 16.6%, 33.3%, 50%, 66.6%, 83.3% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  16.6% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); }
  33.3% {
    -webkit-transform: translate(25px, 0);
    transform: translate(25px, 0); }
  50% {
    -webkit-transform: translate(-12px, 0);
    transform: translate(-12px, 0); }
  66.6% {
    -webkit-transform: translate(6px, 0);
    transform: translate(6px, 0); }
  83.3% {
    -webkit-transform: translate(-2.5px, 0);
    transform: translate(-2.5px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-px-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0); }
  50% {
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-px-ltr {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0); }
  50% {
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-px-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0); }
  50% {
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-px-rtl {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(-100px, 0);
    transform: translate(-100px, 0); }
  50% {
    -webkit-transform: translate(100px, 0);
    transform: translate(100px, 0); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-px-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-px-btt {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-slide-px-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@-webkit-keyframes ld-slide-px-ttb {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5); }
  50% {
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1); }
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  49.9% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); } }

@keyframes ld-tremble-px {
  0% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  5% {
    -webkit-transform: translate(0, 1px);
    transform: translate(0, 1px); }
  10% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  15% {
    -webkit-transform: translate(2px, 1px);
    transform: translate(2px, 1px); }
  20% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0); }
  25% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  30% {
    -webkit-transform: translate(1px, 3px);
    transform: translate(1px, 3px); }
  35% {
    -webkit-transform: translate(0, 1px);
    transform: translate(0, 1px); }
  40% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  45% {
    -webkit-transform: translate(1px, 0);
    transform: translate(1px, 0); }
  50% {
    -webkit-transform: translate(2px, 1px);
    transform: translate(2px, 1px); }
  55% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  60% {
    -webkit-transform: translate(3px, 1px);
    transform: translate(3px, 1px); }
  65% {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px); }
  70% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0); }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  80% {
    -webkit-transform: translate(2px, 3px);
    transform: translate(2px, 3px); }
  85% {
    -webkit-transform: translate(1px, 0);
    transform: translate(1px, 0); }
  90% {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px); }
  95% {
    -webkit-transform: translate(3px, 2px);
    transform: translate(3px, 2px); } }

@-webkit-keyframes ld-tremble-px {
  0% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  5% {
    -webkit-transform: translate(0, 1px);
    transform: translate(0, 1px); }
  10% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  15% {
    -webkit-transform: translate(2px, 1px);
    transform: translate(2px, 1px); }
  20% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0); }
  25% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  30% {
    -webkit-transform: translate(1px, 3px);
    transform: translate(1px, 3px); }
  35% {
    -webkit-transform: translate(0, 1px);
    transform: translate(0, 1px); }
  40% {
    -webkit-transform: translate(1px, 1px);
    transform: translate(1px, 1px); }
  45% {
    -webkit-transform: translate(1px, 0);
    transform: translate(1px, 0); }
  50% {
    -webkit-transform: translate(2px, 1px);
    transform: translate(2px, 1px); }
  55% {
    -webkit-transform: translate(1px, 2px);
    transform: translate(1px, 2px); }
  60% {
    -webkit-transform: translate(3px, 1px);
    transform: translate(3px, 1px); }
  65% {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px); }
  70% {
    -webkit-transform: translate(3px, 0);
    transform: translate(3px, 0); }
  75% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  80% {
    -webkit-transform: translate(2px, 3px);
    transform: translate(2px, 3px); }
  85% {
    -webkit-transform: translate(1px, 0);
    transform: translate(1px, 0); }
  90% {
    -webkit-transform: translate(0, 2px);
    transform: translate(0, 2px); }
  95% {
    -webkit-transform: translate(3px, 2px);
    transform: translate(3px, 2px); } }

@keyframes ld-wander-px-h {
  0% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); }
  50% {
    -webkit-transform: translate(35px, 0);
    transform: translate(35px, 0); }
  100% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); } }

@-webkit-keyframes ld-wander-px-h {
  0% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); }
  50% {
    -webkit-transform: translate(35px, 0);
    transform: translate(35px, 0); }
  100% {
    -webkit-transform: translate(-35px, 0);
    transform: translate(-35px, 0); } }

@keyframes ld-wander-px-v {
  0% {
    -webkit-transform: translate(0, -35px);
    transform: translate(0, -35px); }
  50% {
    -webkit-transform: translate(0, 35px);
    transform: translate(0, 35px); }
  100% {
    -webkit-transform: translate(0, -35px);
    transform: translate(0, -35px); } }

@-webkit-keyframes ld-wander-px-v {
  0% {
    -webkit-transform: translate(0, -35px);
    transform: translate(0, -35px); }
  50% {
    -webkit-transform: translate(0, 35px);
    transform: translate(0, 35px); }
  100% {
    -webkit-transform: translate(0, -35px);
    transform: translate(0, -35px); } }

@keyframes ld-jingle-px {
  0% {
    -webkit-transform: translate(0, -40px) rotate(0deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(0deg) translate(0, 40px); }
  4% {
    -webkit-transform: translate(0, -40px) rotate(11deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(11deg) translate(0, 40px); }
  10% {
    -webkit-transform: translate(0, -40px) rotate(15deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(15deg) translate(0, 40px); }
  18% {
    -webkit-transform: translate(0, -40px) rotate(-11deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-11deg) translate(0, 40px); }
  20% {
    -webkit-transform: translate(0, -40px) rotate(-13deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-13deg) translate(0, 40px); }
  21% {
    -webkit-transform: translate(0, -40px) rotate(-12deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-12deg) translate(0, 40px); }
  22% {
    -webkit-transform: translate(0, -40px) rotate(-10deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-10deg) translate(0, 40px); }
  24% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  26% {
    -webkit-transform: translate(0, -40px) rotate(3deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(3deg) translate(0, 40px); }
  28% {
    -webkit-transform: translate(0, -40px) rotate(9deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(9deg) translate(0, 40px); }
  30% {
    -webkit-transform: translate(0, -40px) rotate(10deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(10deg) translate(0, 40px); }
  31% {
    -webkit-transform: translate(0, -40px) rotate(9deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(9deg) translate(0, 40px); }
  33% {
    -webkit-transform: translate(0, -40px) rotate(5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(5deg) translate(0, 40px); }
  34% {
    -webkit-transform: translate(0, -40px) rotate(1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(1deg) translate(0, 40px); }
  36% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  39% {
    -webkit-transform: translate(0, -40px) rotate(-8deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-8deg) translate(0, 40px); }
  40% {
    -webkit-transform: translate(0, -40px) rotate(-7deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-7deg) translate(0, 40px); }
  44% {
    -webkit-transform: translate(0, -40px) rotate(3deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(3deg) translate(0, 40px); }
  47% {
    -webkit-transform: translate(0, -40px) rotate(7deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(7deg) translate(0, 40px); }
  56% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  63% {
    -webkit-transform: translate(0, -40px) rotate(1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(1deg) translate(0, 40px); }
  75% {
    -webkit-transform: translate(0, -40px) rotate(-1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-1deg) translate(0, 40px); }
  100% {
    -webkit-transform: translate(0, -40px) rotate(0deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(0deg) translate(0, 40px); } }

@-webkit-keyframes ld-jingle-px {
  0% {
    -webkit-transform: translate(0, -40px) rotate(0deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(0deg) translate(0, 40px); }
  4% {
    -webkit-transform: translate(0, -40px) rotate(11deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(11deg) translate(0, 40px); }
  10% {
    -webkit-transform: translate(0, -40px) rotate(15deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(15deg) translate(0, 40px); }
  18% {
    -webkit-transform: translate(0, -40px) rotate(-11deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-11deg) translate(0, 40px); }
  20% {
    -webkit-transform: translate(0, -40px) rotate(-13deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-13deg) translate(0, 40px); }
  21% {
    -webkit-transform: translate(0, -40px) rotate(-12deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-12deg) translate(0, 40px); }
  22% {
    -webkit-transform: translate(0, -40px) rotate(-10deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-10deg) translate(0, 40px); }
  24% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  26% {
    -webkit-transform: translate(0, -40px) rotate(3deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(3deg) translate(0, 40px); }
  28% {
    -webkit-transform: translate(0, -40px) rotate(9deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(9deg) translate(0, 40px); }
  30% {
    -webkit-transform: translate(0, -40px) rotate(10deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(10deg) translate(0, 40px); }
  31% {
    -webkit-transform: translate(0, -40px) rotate(9deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(9deg) translate(0, 40px); }
  33% {
    -webkit-transform: translate(0, -40px) rotate(5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(5deg) translate(0, 40px); }
  34% {
    -webkit-transform: translate(0, -40px) rotate(1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(1deg) translate(0, 40px); }
  36% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  39% {
    -webkit-transform: translate(0, -40px) rotate(-8deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-8deg) translate(0, 40px); }
  40% {
    -webkit-transform: translate(0, -40px) rotate(-7deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-7deg) translate(0, 40px); }
  44% {
    -webkit-transform: translate(0, -40px) rotate(3deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(3deg) translate(0, 40px); }
  47% {
    -webkit-transform: translate(0, -40px) rotate(7deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(7deg) translate(0, 40px); }
  56% {
    -webkit-transform: translate(0, -40px) rotate(-5deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-5deg) translate(0, 40px); }
  63% {
    -webkit-transform: translate(0, -40px) rotate(1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(1deg) translate(0, 40px); }
  75% {
    -webkit-transform: translate(0, -40px) rotate(-1deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(-1deg) translate(0, 40px); }
  100% {
    -webkit-transform: translate(0, -40px) rotate(0deg) translate(0, 40px);
    transform: translate(0, -40px) rotate(0deg) translate(0, 40px); } }

@keyframes ld-swim-px {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(1px, -2px) rotate(3deg);
    transform: translate(1px, -2px) rotate(3deg); }
  25% {
    -webkit-transform: translate(0, -3px) rotate(6deg);
    transform: translate(0, -3px) rotate(6deg); }
  37.5% {
    -webkit-transform: translate(-1px, -2px) rotate(3deg);
    transform: translate(-1px, -2px) rotate(3deg); }
  50% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  62.5% {
    -webkit-transform: translate(1px, 2px) rotate(-3deg);
    transform: translate(1px, 2px) rotate(-3deg); }
  75% {
    -webkit-transform: translate(0, 3px) rotate(-6deg);
    transform: translate(0, 3px) rotate(-6deg); }
  87.5% {
    -webkit-transform: translate(-1px, 2px) rotate(-3deg);
    transform: translate(-1px, 2px) rotate(-3deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); } }

@-webkit-keyframes ld-swim-px {
  0% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  12.5% {
    -webkit-transform: translate(1px, -2px) rotate(3deg);
    transform: translate(1px, -2px) rotate(3deg); }
  25% {
    -webkit-transform: translate(0, -3px) rotate(6deg);
    transform: translate(0, -3px) rotate(6deg); }
  37.5% {
    -webkit-transform: translate(-1px, -2px) rotate(3deg);
    transform: translate(-1px, -2px) rotate(3deg); }
  50% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); }
  62.5% {
    -webkit-transform: translate(1px, 2px) rotate(-3deg);
    transform: translate(1px, 2px) rotate(-3deg); }
  75% {
    -webkit-transform: translate(0, 3px) rotate(-6deg);
    transform: translate(0, 3px) rotate(-6deg); }
  87.5% {
    -webkit-transform: translate(-1px, 2px) rotate(-3deg);
    transform: translate(-1px, 2px) rotate(-3deg); }
  100% {
    -webkit-transform: translate(0, 0) rotate(0deg);
    transform: translate(0, 0) rotate(0deg); } }

@keyframes ld-leaf-px {
  0% {
    -webkit-transform: translate(-14.7px, -117px) rotate(-0.3deg);
    transform: translate(-14.7px, -117px) rotate(-0.3deg); }
  1% {
    -webkit-transform: translate(-14.4px, -112.5px) rotate(-0.6deg);
    transform: translate(-14.4px, -112.5px) rotate(-0.6deg); }
  2% {
    -webkit-transform: translate(-13.8px, -102px) rotate(-1.2deg);
    transform: translate(-13.8px, -102px) rotate(-1.2deg); }
  3% {
    -webkit-transform: translate(-13.5px, -100.5px) rotate(-1.5deg);
    transform: translate(-13.5px, -100.5px) rotate(-1.5deg); }
  5% {
    -webkit-transform: translate(-7.2px, -87px) rotate(-7.8deg);
    transform: translate(-7.2px, -87px) rotate(-7.8deg); }
  7% {
    -webkit-transform: translate(5.4px, -79.5px) rotate(-20.4deg);
    transform: translate(5.4px, -79.5px) rotate(-20.4deg); }
  10% {
    -webkit-transform: translate(12.9px, -76.5px) rotate(-27.9deg);
    transform: translate(12.9px, -76.5px) rotate(-27.9deg); }
  12% {
    -webkit-transform: translate(14.4px, -75px) rotate(-29.4deg);
    transform: translate(14.4px, -75px) rotate(-29.4deg); }
  13% {
    -webkit-transform: translate(14.7px, -75px) rotate(-29.7deg);
    transform: translate(14.7px, -75px) rotate(-29.7deg); }
  14% {
    -webkit-transform: translate(15px, -75px) rotate(-30deg);
    transform: translate(15px, -75px) rotate(-30deg); }
  15% {
    -webkit-transform: translate(14.4px, -69px) rotate(0.6deg);
    transform: translate(14.4px, -69px) rotate(0.6deg); }
  16% {
    -webkit-transform: translate(13.8px, -58.5px) rotate(1.2deg);
    transform: translate(13.8px, -58.5px) rotate(1.2deg); }
  19% {
    -webkit-transform: translate(7.2px, -45px) rotate(7.8deg);
    transform: translate(7.2px, -45px) rotate(7.8deg); }
  21% {
    -webkit-transform: translate(-5.4px, -37.5px) rotate(20.4deg);
    transform: translate(-5.4px, -37.5px) rotate(20.4deg); }
  24% {
    -webkit-transform: translate(-12.9px, -33px) rotate(27.9deg);
    transform: translate(-12.9px, -33px) rotate(27.9deg); }
  26% {
    -webkit-transform: translate(-14.4px, -33px) rotate(29.4deg);
    transform: translate(-14.4px, -33px) rotate(29.4deg); }
  27% {
    -webkit-transform: translate(-14.7px, -31.5px) rotate(29.7deg);
    transform: translate(-14.7px, -31.5px) rotate(29.7deg); }
  28% {
    -webkit-transform: translate(-15px, -31.5px) rotate(30deg);
    transform: translate(-15px, -31.5px) rotate(30deg); }
  29% {
    -webkit-transform: translate(-14.4px, -27px) rotate(-0.6deg);
    transform: translate(-14.4px, -27px) rotate(-0.6deg); }
  30% {
    -webkit-transform: translate(-13.8px, -16.5px) rotate(-1.2deg);
    transform: translate(-13.8px, -16.5px) rotate(-1.2deg); }
  31% {
    -webkit-transform: translate(-13.5px, -15px) rotate(-1.5deg);
    transform: translate(-13.5px, -15px) rotate(-1.5deg); }
  33% {
    -webkit-transform: translate(-7.2px, -1.5px) rotate(-7.8deg);
    transform: translate(-7.2px, -1.5px) rotate(-7.8deg); }
  36% {
    -webkit-transform: translate(5.4px, 4.5px) rotate(-20.4deg);
    transform: translate(5.4px, 4.5px) rotate(-20.4deg); }
  38% {
    -webkit-transform: translate(12.9px, 9px) rotate(-27.9deg);
    transform: translate(12.9px, 9px) rotate(-27.9deg); }
  40% {
    -webkit-transform: translate(14.4px, 10.5px) rotate(-29.4deg);
    transform: translate(14.4px, 10.5px) rotate(-29.4deg); }
  41% {
    -webkit-transform: translate(14.7px, 10.5px) rotate(-29.7deg);
    transform: translate(14.7px, 10.5px) rotate(-29.7deg); }
  42% {
    -webkit-transform: translate(15px, 10.5px) rotate(-30deg);
    transform: translate(15px, 10.5px) rotate(-30deg); }
  43% {
    -webkit-transform: translate(15px, 10.5px) rotate(-30deg);
    transform: translate(15px, 10.5px) rotate(-30deg); }
  43% {
    -webkit-transform: translate(14.7px, 10.5px) rotate(0.3deg);
    transform: translate(14.7px, 10.5px) rotate(0.3deg); }
  43% {
    -webkit-transform: translate(14.4px, 16.5px) rotate(0.6deg);
    transform: translate(14.4px, 16.5px) rotate(0.6deg); }
  45% {
    -webkit-transform: translate(13.8px, 25.5px) rotate(1.2deg);
    transform: translate(13.8px, 25.5px) rotate(1.2deg); }
  45% {
    -webkit-transform: translate(13.5px, 27px) rotate(1.5deg);
    transform: translate(13.5px, 27px) rotate(1.5deg); }
  48% {
    -webkit-transform: translate(7.2px, 40.5px) rotate(7.8deg);
    transform: translate(7.2px, 40.5px) rotate(7.8deg); }
  50% {
    -webkit-transform: translate(-5.4px, 48px) rotate(20.4deg);
    transform: translate(-5.4px, 48px) rotate(20.4deg); }
  52% {
    -webkit-transform: translate(-12.9px, 51px) rotate(27.9deg);
    transform: translate(-12.9px, 51px) rotate(27.9deg); }
  54% {
    -webkit-transform: translate(-14.4px, 52.5px) rotate(29.4deg);
    transform: translate(-14.4px, 52.5px) rotate(29.4deg); }
  56% {
    -webkit-transform: translate(-14.7px, 54px) rotate(29.7deg);
    transform: translate(-14.7px, 54px) rotate(29.7deg); }
  57% {
    -webkit-transform: translate(-14.7px, 54px) rotate(-0.3deg);
    transform: translate(-14.7px, 54px) rotate(-0.3deg); }
  58% {
    -webkit-transform: translate(-14.4px, 58.5px) rotate(-0.6deg);
    transform: translate(-14.4px, 58.5px) rotate(-0.6deg); }
  59% {
    -webkit-transform: translate(-13.5px, 70.5px) rotate(-1.5deg);
    transform: translate(-13.5px, 70.5px) rotate(-1.5deg); }
  62% {
    -webkit-transform: translate(-7.2px, 84px) rotate(-7.8deg);
    transform: translate(-7.2px, 84px) rotate(-7.8deg); }
  64% {
    -webkit-transform: translate(5.4px, 91.5px) rotate(-20.4deg);
    transform: translate(5.4px, 91.5px) rotate(-20.4deg); }
  67% {
    -webkit-transform: translate(12.9px, 94.5px) rotate(-27.9deg);
    transform: translate(12.9px, 94.5px) rotate(-27.9deg); }
  69% {
    -webkit-transform: translate(14.4px, 96px) rotate(-29.4deg);
    transform: translate(14.4px, 96px) rotate(-29.4deg); }
  70% {
    -webkit-transform: translate(14.7px, 96px) rotate(-29.7deg);
    transform: translate(14.7px, 96px) rotate(-29.7deg); }
  71% {
    -webkit-transform: translate(15px, 96px) rotate(-30deg);
    transform: translate(15px, 96px) rotate(-30deg); }
  72% {
    -webkit-transform: translate(14.4px, 102px) rotate(0.6deg);
    transform: translate(14.4px, 102px) rotate(0.6deg); }
  73% {
    -webkit-transform: translate(13.8px, 111px) rotate(1.2deg);
    transform: translate(13.8px, 111px) rotate(1.2deg); }
  74% {
    -webkit-transform: translate(13.5px, 112.5px) rotate(1.5deg);
    transform: translate(13.5px, 112.5px) rotate(1.5deg); }
  76% {
    -webkit-transform: translate(7.2px, 126px) rotate(7.8deg);
    transform: translate(7.2px, 126px) rotate(7.8deg); }
  79% {
    -webkit-transform: translate(-5.4px, 133.5px) rotate(20.4deg);
    transform: translate(-5.4px, 133.5px) rotate(20.4deg); }
  81% {
    -webkit-transform: translate(-12.9px, 138px) rotate(27.9deg);
    transform: translate(-12.9px, 138px) rotate(27.9deg); }
  83% {
    -webkit-transform: translate(-14.4px, 139.5px) rotate(29.4deg);
    transform: translate(-14.4px, 139.5px) rotate(29.4deg); }
  84% {
    -webkit-transform: translate(-14.7px, 139.5px) rotate(29.7deg);
    transform: translate(-14.7px, 139.5px) rotate(29.7deg); }
  85% {
    -webkit-transform: translate(-15px, 139.5px) rotate(30deg);
    transform: translate(-15px, 139.5px) rotate(30deg); }
  86% {
    -webkit-transform: translate(-14.7px, 139.5px) rotate(-0.3deg);
    transform: translate(-14.7px, 139.5px) rotate(-0.3deg); }
  86% {
    -webkit-transform: translate(-14.4px, 144px) rotate(-0.6deg);
    transform: translate(-14.4px, 144px) rotate(-0.6deg); }
  88% {
    -webkit-transform: translate(-13.5px, 156px) rotate(-1.5deg);
    transform: translate(-13.5px, 156px) rotate(-1.5deg); }
  90% {
    -webkit-transform: translate(-7.2px, 169.5px) rotate(-7.8deg);
    transform: translate(-7.2px, 169.5px) rotate(-7.8deg); }
  93% {
    -webkit-transform: translate(5.4px, 177px) rotate(-20.4deg);
    transform: translate(5.4px, 177px) rotate(-20.4deg); }
  95% {
    -webkit-transform: translate(12.9px, 180px) rotate(-27.9deg);
    transform: translate(12.9px, 180px) rotate(-27.9deg); }
  97% {
    -webkit-transform: translate(14.4px, 181.5px) rotate(-29.4deg);
    transform: translate(14.4px, 181.5px) rotate(-29.4deg); }
  99% {
    -webkit-transform: translate(14.7px, 181.5px) rotate(-29.7deg);
    transform: translate(14.7px, 181.5px) rotate(-29.7deg); }
  100% {
    -webkit-transform: translate(15px, 181.5px) rotate(-30deg);
    transform: translate(15px, 181.5px) rotate(-30deg); } }

@-webkit-keyframes ld-leaf-px {
  0% {
    -webkit-transform: translate(-14.7px, -117px) rotate(-0.3deg);
    transform: translate(-14.7px, -117px) rotate(-0.3deg); }
  1% {
    -webkit-transform: translate(-14.4px, -112.5px) rotate(-0.6deg);
    transform: translate(-14.4px, -112.5px) rotate(-0.6deg); }
  2% {
    -webkit-transform: translate(-13.8px, -102px) rotate(-1.2deg);
    transform: translate(-13.8px, -102px) rotate(-1.2deg); }
  3% {
    -webkit-transform: translate(-13.5px, -100.5px) rotate(-1.5deg);
    transform: translate(-13.5px, -100.5px) rotate(-1.5deg); }
  5% {
    -webkit-transform: translate(-7.2px, -87px) rotate(-7.8deg);
    transform: translate(-7.2px, -87px) rotate(-7.8deg); }
  7% {
    -webkit-transform: translate(5.4px, -79.5px) rotate(-20.4deg);
    transform: translate(5.4px, -79.5px) rotate(-20.4deg); }
  10% {
    -webkit-transform: translate(12.9px, -76.5px) rotate(-27.9deg);
    transform: translate(12.9px, -76.5px) rotate(-27.9deg); }
  12% {
    -webkit-transform: translate(14.4px, -75px) rotate(-29.4deg);
    transform: translate(14.4px, -75px) rotate(-29.4deg); }
  13% {
    -webkit-transform: translate(14.7px, -75px) rotate(-29.7deg);
    transform: translate(14.7px, -75px) rotate(-29.7deg); }
  14% {
    -webkit-transform: translate(15px, -75px) rotate(-30deg);
    transform: translate(15px, -75px) rotate(-30deg); }
  15% {
    -webkit-transform: translate(14.4px, -69px) rotate(0.6deg);
    transform: translate(14.4px, -69px) rotate(0.6deg); }
  16% {
    -webkit-transform: translate(13.8px, -58.5px) rotate(1.2deg);
    transform: translate(13.8px, -58.5px) rotate(1.2deg); }
  19% {
    -webkit-transform: translate(7.2px, -45px) rotate(7.8deg);
    transform: translate(7.2px, -45px) rotate(7.8deg); }
  21% {
    -webkit-transform: translate(-5.4px, -37.5px) rotate(20.4deg);
    transform: translate(-5.4px, -37.5px) rotate(20.4deg); }
  24% {
    -webkit-transform: translate(-12.9px, -33px) rotate(27.9deg);
    transform: translate(-12.9px, -33px) rotate(27.9deg); }
  26% {
    -webkit-transform: translate(-14.4px, -33px) rotate(29.4deg);
    transform: translate(-14.4px, -33px) rotate(29.4deg); }
  27% {
    -webkit-transform: translate(-14.7px, -31.5px) rotate(29.7deg);
    transform: translate(-14.7px, -31.5px) rotate(29.7deg); }
  28% {
    -webkit-transform: translate(-15px, -31.5px) rotate(30deg);
    transform: translate(-15px, -31.5px) rotate(30deg); }
  29% {
    -webkit-transform: translate(-14.4px, -27px) rotate(-0.6deg);
    transform: translate(-14.4px, -27px) rotate(-0.6deg); }
  30% {
    -webkit-transform: translate(-13.8px, -16.5px) rotate(-1.2deg);
    transform: translate(-13.8px, -16.5px) rotate(-1.2deg); }
  31% {
    -webkit-transform: translate(-13.5px, -15px) rotate(-1.5deg);
    transform: translate(-13.5px, -15px) rotate(-1.5deg); }
  33% {
    -webkit-transform: translate(-7.2px, -1.5px) rotate(-7.8deg);
    transform: translate(-7.2px, -1.5px) rotate(-7.8deg); }
  36% {
    -webkit-transform: translate(5.4px, 4.5px) rotate(-20.4deg);
    transform: translate(5.4px, 4.5px) rotate(-20.4deg); }
  38% {
    -webkit-transform: translate(12.9px, 9px) rotate(-27.9deg);
    transform: translate(12.9px, 9px) rotate(-27.9deg); }
  40% {
    -webkit-transform: translate(14.4px, 10.5px) rotate(-29.4deg);
    transform: translate(14.4px, 10.5px) rotate(-29.4deg); }
  41% {
    -webkit-transform: translate(14.7px, 10.5px) rotate(-29.7deg);
    transform: translate(14.7px, 10.5px) rotate(-29.7deg); }
  42% {
    -webkit-transform: translate(15px, 10.5px) rotate(-30deg);
    transform: translate(15px, 10.5px) rotate(-30deg); }
  43% {
    -webkit-transform: translate(15px, 10.5px) rotate(-30deg);
    transform: translate(15px, 10.5px) rotate(-30deg); }
  43% {
    -webkit-transform: translate(14.7px, 10.5px) rotate(0.3deg);
    transform: translate(14.7px, 10.5px) rotate(0.3deg); }
  43% {
    -webkit-transform: translate(14.4px, 16.5px) rotate(0.6deg);
    transform: translate(14.4px, 16.5px) rotate(0.6deg); }
  45% {
    -webkit-transform: translate(13.8px, 25.5px) rotate(1.2deg);
    transform: translate(13.8px, 25.5px) rotate(1.2deg); }
  45% {
    -webkit-transform: translate(13.5px, 27px) rotate(1.5deg);
    transform: translate(13.5px, 27px) rotate(1.5deg); }
  48% {
    -webkit-transform: translate(7.2px, 40.5px) rotate(7.8deg);
    transform: translate(7.2px, 40.5px) rotate(7.8deg); }
  50% {
    -webkit-transform: translate(-5.4px, 48px) rotate(20.4deg);
    transform: translate(-5.4px, 48px) rotate(20.4deg); }
  52% {
    -webkit-transform: translate(-12.9px, 51px) rotate(27.9deg);
    transform: translate(-12.9px, 51px) rotate(27.9deg); }
  54% {
    -webkit-transform: translate(-14.4px, 52.5px) rotate(29.4deg);
    transform: translate(-14.4px, 52.5px) rotate(29.4deg); }
  56% {
    -webkit-transform: translate(-14.7px, 54px) rotate(29.7deg);
    transform: translate(-14.7px, 54px) rotate(29.7deg); }
  57% {
    -webkit-transform: translate(-14.7px, 54px) rotate(-0.3deg);
    transform: translate(-14.7px, 54px) rotate(-0.3deg); }
  58% {
    -webkit-transform: translate(-14.4px, 58.5px) rotate(-0.6deg);
    transform: translate(-14.4px, 58.5px) rotate(-0.6deg); }
  59% {
    -webkit-transform: translate(-13.5px, 70.5px) rotate(-1.5deg);
    transform: translate(-13.5px, 70.5px) rotate(-1.5deg); }
  62% {
    -webkit-transform: translate(-7.2px, 84px) rotate(-7.8deg);
    transform: translate(-7.2px, 84px) rotate(-7.8deg); }
  64% {
    -webkit-transform: translate(5.4px, 91.5px) rotate(-20.4deg);
    transform: translate(5.4px, 91.5px) rotate(-20.4deg); }
  67% {
    -webkit-transform: translate(12.9px, 94.5px) rotate(-27.9deg);
    transform: translate(12.9px, 94.5px) rotate(-27.9deg); }
  69% {
    -webkit-transform: translate(14.4px, 96px) rotate(-29.4deg);
    transform: translate(14.4px, 96px) rotate(-29.4deg); }
  70% {
    -webkit-transform: translate(14.7px, 96px) rotate(-29.7deg);
    transform: translate(14.7px, 96px) rotate(-29.7deg); }
  71% {
    -webkit-transform: translate(15px, 96px) rotate(-30deg);
    transform: translate(15px, 96px) rotate(-30deg); }
  72% {
    -webkit-transform: translate(14.4px, 102px) rotate(0.6deg);
    transform: translate(14.4px, 102px) rotate(0.6deg); }
  73% {
    -webkit-transform: translate(13.8px, 111px) rotate(1.2deg);
    transform: translate(13.8px, 111px) rotate(1.2deg); }
  74% {
    -webkit-transform: translate(13.5px, 112.5px) rotate(1.5deg);
    transform: translate(13.5px, 112.5px) rotate(1.5deg); }
  76% {
    -webkit-transform: translate(7.2px, 126px) rotate(7.8deg);
    transform: translate(7.2px, 126px) rotate(7.8deg); }
  79% {
    -webkit-transform: translate(-5.4px, 133.5px) rotate(20.4deg);
    transform: translate(-5.4px, 133.5px) rotate(20.4deg); }
  81% {
    -webkit-transform: translate(-12.9px, 138px) rotate(27.9deg);
    transform: translate(-12.9px, 138px) rotate(27.9deg); }
  83% {
    -webkit-transform: translate(-14.4px, 139.5px) rotate(29.4deg);
    transform: translate(-14.4px, 139.5px) rotate(29.4deg); }
  84% {
    -webkit-transform: translate(-14.7px, 139.5px) rotate(29.7deg);
    transform: translate(-14.7px, 139.5px) rotate(29.7deg); }
  85% {
    -webkit-transform: translate(-15px, 139.5px) rotate(30deg);
    transform: translate(-15px, 139.5px) rotate(30deg); }
  86% {
    -webkit-transform: translate(-14.7px, 139.5px) rotate(-0.3deg);
    transform: translate(-14.7px, 139.5px) rotate(-0.3deg); }
  86% {
    -webkit-transform: translate(-14.4px, 144px) rotate(-0.6deg);
    transform: translate(-14.4px, 144px) rotate(-0.6deg); }
  88% {
    -webkit-transform: translate(-13.5px, 156px) rotate(-1.5deg);
    transform: translate(-13.5px, 156px) rotate(-1.5deg); }
  90% {
    -webkit-transform: translate(-7.2px, 169.5px) rotate(-7.8deg);
    transform: translate(-7.2px, 169.5px) rotate(-7.8deg); }
  93% {
    -webkit-transform: translate(5.4px, 177px) rotate(-20.4deg);
    transform: translate(5.4px, 177px) rotate(-20.4deg); }
  95% {
    -webkit-transform: translate(12.9px, 180px) rotate(-27.9deg);
    transform: translate(12.9px, 180px) rotate(-27.9deg); }
  97% {
    -webkit-transform: translate(14.4px, 181.5px) rotate(-29.4deg);
    transform: translate(14.4px, 181.5px) rotate(-29.4deg); }
  99% {
    -webkit-transform: translate(14.7px, 181.5px) rotate(-29.7deg);
    transform: translate(14.7px, 181.5px) rotate(-29.7deg); }
  100% {
    -webkit-transform: translate(15px, 181.5px) rotate(-30deg);
    transform: translate(15px, 181.5px) rotate(-30deg); } }

@keyframes ld-slot-px {
  0% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  9.09% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  9.1% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  16.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  17% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  23.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  23.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  29.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  29.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  34.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  34.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  38.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  38.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  41.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  41.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  44.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  44.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  46.29% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  46.3% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  47.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  47.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  48.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  48.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  49.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  49.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  51.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  51.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  52.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  52.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  53.690000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  53.7% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  55.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  55.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  58.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  58.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  61.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  61.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  65.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  65.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  70.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  70.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  76.19% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  76.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  82.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  83% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  90.89% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  90.9% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  99.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  100% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); } }

@-webkit-keyframes ld-slot-px {
  0% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  9.09% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  9.1% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  16.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  17% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  23.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  23.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  29.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  29.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  34.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  34.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  38.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  38.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  41.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  41.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  44.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  44.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  46.29% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  46.3% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  47.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  47.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  48.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  48.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  49.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.79% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  49.8% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  49.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  50.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  50.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  51.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  51.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  52.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  52.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  53.690000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  53.7% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  55.59% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  55.6% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  58.190000000000005% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  58.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  61.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  61.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  65.49% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  65.5% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  70.39% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  70.4% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  76.19% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  76.2% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  82.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  83% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  90.89% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  90.9% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); }
  99.99% {
    -webkit-transform: translate(0, 100px);
    transform: translate(0, 100px); }
  100% {
    -webkit-transform: translate(0, -100px);
    transform: translate(0, -100px); } }

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  transition: background-color .3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 15; }
