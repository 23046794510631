.chart-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 100%;


  .the-chart {
    height: 100%;
    flex-grow: 1;
  }
  .chart-btn {
    font-size: 1.5em;
    text-align: center;
    width: 2em;
    height: 2em;
    background-color: transparent;
    color: #ababab;
    align-self: center;
    border-radius: 50%;
    //border: 2px solid #ababab;
    cursor: pointer;
    outline: none;
    &:hover {
      opacity: 0.7;
    }
    &.btn-left {
      margin-right: 0.5rem;
    }
    &.btn-right {
      margin-left: 0.5rem;
    }
  }
}
