.lend-list-item-comp {
  display: flex;
  justify-content: space-between;

  .right-part {
    .remove-btn-container {
      text-align: right;
      .remove-btn {
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
