.comp-admin-more {
  display: flex;
  align-items: center;
  flex-direction: column;

  .list {
    width: 80%;
    max-width: 50em;
    min-width: 30em;
    margin-bottom: 2rem;
    .sub-list {
      margin: 0 auto 1rem;

      .list-item {
        font-weight: bold;
      }
    }
  }
}
