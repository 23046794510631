.patron-details-comp {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 11em);

  .box {
    width: 60%;

    .details-container {
      .top-bar {
        margin-bottom: 0.6rem;
        padding: 0 1rem 0.0rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;

        .admin-label {
          margin-bottom: 0;
        }
      }
    }

    .show-history-button {
      margin-top: 0.6rem;
    }
  }
}
