.history-detail-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: whitesmoke !important;
  }
}
