.search-dropdown {
  &.is-fluid {
    width: 100%;
  }
  .dropdown-trigger {
    &.is-fluid {
      width: 100%;
    }
    .button {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
  .dropdown-menu {
    max-height: 200px;
    overflow: auto;
    .input-field {
      padding: 0 5px 8px;
    }
  }
}
