/*Screen Sizes*/
/*Colors*/
.comp-header {
  padding: 0.5rem 0;
  text-align: center;
  margin-bottom: 0.4rem !important;
  width: 100%; }

.comp-header ~ .comp-container {
  min-height: calc(100vh - 8em) !important; }

.comp-container {
  min-height: calc(100vh - 6.2em);
  box-sizing: border-box; }

.center,
.center-column,
.top,
.right,
.bottom,
.left {
  display: flex;
  justify-content: center;
  align-items: center; }

.center-column {
  flex-direction: column; }

.top {
  align-items: flex-start; }

.right {
  justify-content: flex-end; }

.bottom {
  align-items: flex-end; }

.left {
  justify-content: flex-start; }

.single-spaced,
.single-spaced * {
  line-height: 1; }

@media (max-width: 1024px) {
  .desktop {
    display: none; } }

.navbar-has-shadow {
  -webkit-box-shadow: 0px 1px 5px 0px #616161;
  box-shadow: 0px 1px 5px 0px #616161; }

.navbar-is-active {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1); }

.navbar-item:hover {
  -webkit-box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0.21);
  box-shadow: inset 0px 0px 50px 50px rgba(0, 0, 0, 0.21); }

.darken {
  -webkit-box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.1); }

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 20; }

.hover-pointer {
  cursor: pointer !important; }

.is-unselectable {
  user-select: none !important; }

.is-unfocusable {
  pointer-events: none !important; }

.is-spinning {
  animation-name: spin;
  animation-duration: 5000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear; }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }
