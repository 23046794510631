.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000; }

.custom-dropdown {
  width: 10rem;
  position: absolute;
  border: #b5b5b5 solid thin;
  padding: 0.2rem 0;
  border-radius: 0.4em;
  display: none; }

.custom-dropdown-nav-item:hover > .custom-dropdown, .custom-dropdown-item:hover > .custom-dropdown {
  display: block; }

.custom-dropdown-menu {
  bottom: 0;
  left: 0;
  transform: translateY(100%); }
  .custom-dropdown-menu .dropdown-imp, .custom-dropdown-menu .dropdown-imp-unselectable {
    border-bottom: #b5b5b5 solid thin;
    margin-bottom: 0.4rem; }
  .custom-dropdown-menu .custom-dropdown-item.dropdown-imp-unselectable:hover {
    cursor: default;
    box-shadow: inset 0 0 0 0 white;
    color: black; }
  .custom-dropdown-menu .custom-dropdown-item, .custom-dropdown-menu .custom-dropdown-item-unselectable {
    padding: 0.4rem 1rem;
    user-select: none; }
  .custom-dropdown-menu > .dropdown-parent-item {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .custom-dropdown-menu > .dropdown-parent-item:hover {
    -webkit-box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.336);
    box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.336);
    color: white !important; }
  .custom-dropdown-menu .dropdown-child-item {
    color: black; }
  .custom-dropdown-menu .dropdown-child-item:hover {
    -webkit-box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.336);
    box-shadow: inset 0px 0px 99999px 99999px rgba(0, 0, 0, 0.336);
    color: white; }
  .custom-dropdown-menu .custom-subdropdown-menu {
    top: 0;
    right: 0;
    transform: translate(100%, 60%); }

.custom-dropdown-nav-item.custom-dropdown-right > .custom-dropdown {
  right: 0;
  min-width: 15rem;
  left: auto; }

/*Side Navbar*/
.side-navbar {
  position: fixed;
  right: 0;
  z-index: 200;
  min-width: 17rem;
  padding: 1rem 1rem 4rem 1rem;
  border: #b5b5b5 solid thin;
  height: 100vh;
  overflow: auto;
  -webkit-box-shadow: -1px 0 10px 0 rgba(0, 0, 0, 0.3);
  box-shadow: -1px 0 10px 0 rgba(0, 0, 0, 0.3); }

.side-nav-header {
  padding: 0 1rem;
  border-bottom: #b5b5b5 solid thin; }

.side-navbar-gone {
  transform: translateX(100%);
  -webkit-transition: transform 200ms;
  -moz-transition: transform 200ms;
  -ms-transition: transform 200ms;
  -o-transition: transform 200ms;
  transition: transform 200ms; }

.side-navbar-visible {
  transform: translateX(0%);
  -webkit-transition: transform 200ms;
  -moz-transition: transform 200ms;
  -ms-transition: transform 200ms;
  -o-transition: transform 200ms;
  transition: transform 200ms; }

.side-nav-dropdown {
  display: inline-flex;
  justify-content: space-between;
  align-content: space-between; }
