/*Screen Sizes*/
/*Colors*/
.comp-container.comp-assign-barcode {
  justify-content: space-around; }
  .comp-container.comp-assign-barcode .title-author-input-container {
    width: 100%;
    margin-top: 1rem; }
    .comp-container.comp-assign-barcode .title-author-input-container .input-container {
      height: 7em;
      justify-content: space-between; }
  .comp-container.comp-assign-barcode .button {
    width: 6em; }
  .comp-container.comp-assign-barcode .list-container {
    display: grid;
    width: 100%;
    grid-row-gap: 0.5rem;
    margin-bottom: 2rem; }
    .comp-container.comp-assign-barcode .list-container .list-item-ab {
      background-color: #fbfcf7;
      padding: 0.5rem;
      overflow: hidden; }

.swal2-input {
  min-width: 90%; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
