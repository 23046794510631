/*Screen Sizes*/
/*Colors*/
.greeting {
  padding: 0.5rem 0.5rem;
  box-sizing: border-box; }

.section {
  margin-bottom: 2rem; }
  .section .section-header {
    padding: 0.2rem 1rem; }
  .section .section-container {
    box-sizing: border-box; }
    .section .section-container .box {
      background-color: #fbfcf7;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: space-between;
      font-size: 1.2em;
      user-select: none; }
    .section .section-container .box-link {
      cursor: pointer; }
    .section .section-container .box-link:hover {
      box-shadow: 0 3px 4px rgba(10, 10, 10, 0.1), 0 0 0 2px rgba(10, 10, 10, 0.1); }
    .section .section-container .box-big {
      min-width: 8em;
      min-height: 8em;
      width: 60%;
      height: 60%; }
      .section .section-container .box-big .box-label-top {
        display: block;
        width: 100%;
        text-align: center; }
      .section .section-container .box-big .button {
        margin-top: 0.4rem;
        width: 100%; }
        .section .section-container .box-big .button i {
          margin-right: 1rem; }
