@import "../../styles/variables.scss";
@import "../../styles/size-mixins.scss";

.item-details-comp {
  padding-bottom: 0.8rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $blue-grey-400;

  .title-container {
    border-bottom: 1px solid $blue-grey-400;
    font-size: 1.5em;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.2rem 1.6rem;
    margin-bottom: 1rem;

    .title-label {
      font-weight: bold;

      .fav-btn {
        margin-right: 0.5rem;
        cursor: pointer;
      }
    }
  }

  .details-container {
    padding: 0 1rem;

    .item-details-container {
      margin-right: 1rem;

      .item-details-list-item {

        @include for-size-down(768px) {
          .detail-label {
            padding-bottom: 0 !important;
          }

          .detail-value {
            padding-top: 0 !important;
          }
        }
        &:not(:last-child) {
          border-bottom: 1px solid $blue-grey-300;
        }
      }
    }

    .copy-details-container {
      .copies-container {
        &:not(:last-child) {
          margin-bottom: 1rem;
        }

        .copies-container-header {
          font-size: 1.2em;
          margin-bottom: 0.2rem;
        }
      }
    }
  }
}
