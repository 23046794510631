/*Screen Sizes*/
$size-phone: 599px;
$size-tablet-port: 600px;
$size-tablet-land: 900px;
$size-desktop: 1200px;
$size-widescreen: 1800px;

/*Colors*/
//brand colors
$dark-blue: #3f51b5;
$light-blue: #4FC3F7;
$blue: #2196f3;
//other colors
$orange: #FF9800;
$beige-light: #eff0eb;
$beige-lighter: #fbfcf7;
$light-grey: #b5b5b5;
$color-white: #FFF;
$color-red: #f44336;
$color-dark-blue: #3f51b5;
$color-light-blue: #4FC3F7;
$color-pink: #E91E63;
$color-purple: #9C27B0;
$color-cyan: #00BCD4;
$color-teal: #009688;
$color-lime: #CDDC39;
$color-amber: #FFC107;
$color-deep-orange: #FF5722;
$color-blue-grey: #607D8B;
$blue-grey-300: #90A4AE;
$blue-grey-400: #78909C;
$overlay: rgba(0, 0, 0, 0.1);