.borrow-add-comp {
  max-width: 45em;
  min-width: 30em;
  margin: auto;
  .button-footer {
    display: flex;
    justify-content: flex-end;
    border-top: solid 1px lightgrey;
    margin-top: 0.5rem;
    padding-top: 1rem;
  }
}
