.borrow-comp {
  .comp-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inputs-container {
      display: flex;
      justify-content: center;
    }


    .view-comp {
      width: 80%;
      min-width: 30em;
      max-width: 70em;

      .filter-container {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      .borrow-list {
        margin: 1rem 0;
        .borrow-list-item {
          justify-content: space-between;
          cursor: pointer;
          &:hover {
            background-color: whitesmoke !important;
          }
          .large-text {
            font-size: 1.2em;
          }
          .right-container {
            justify-content: space-evenly;

          }
        }
      }
    }
    .detail-comp {
      width: 60%;
      min-width: 30em;
      max-width: 70em;
      margin-bottom: 1rem;

      .header {
        position: relative;
        .back-btn {
          position: absolute;
          left: 0;
          cursor: pointer;
          font-size: 0.8em;
          &:hover {
            opacity: 0.8;
          }
          //justify-content: start;
        }
      }
      .details {
        .detail-item {
          font-size: 1.1em;
          border-bottom: 1px solid darkgrey;
          padding-bottom: 0.5rem;
          margin-bottom: 0.5rem;
          .detail-label {
            min-width: 120px;
            margin-right: 1rem;
          }
          .detail-value {
            flex-grow: 1;
          }
        }

        .detail-edit-btn {
          justify-self: flex-end;
          margin-right: 0.5rem;
        }
      }

    }
  }
}
