.comp-history {
  .info-bar {
    padding: 0.2rem 0.4rem;
    justify-content: space-between;
    width: 100%;
    //margin-bottom: 1rem;

    .right-part {
      width: 45%;
      justify-content: space-between;
    }
  }

  .history-list {
    margin-top: 3rem;
  }
}
